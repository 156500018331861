<!-- 
    Select a category 
    : views/mobile/SelectCategory.vue
-->
<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid>
            <div class="select-category">
                <mobile-search v-if="mobile_mode" :pagename="page_name"></mobile-search>
                <v-card class="component content" :loading="loading">
                    <v-card-text>
                        <category-list :data="phrases_categories"></category-list>
                    </v-card-text>
                </v-card>
            </div>
        </v-container>
    </section>
</template>

<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});

export default {
    name: 'select-category',
    data() {
        return {
            phrases_categories: [],
            dashboard_breadcrumbs: [],
            loading: false,
            orig_categories: [],
            all_phrases_get: []
        };
    },
    watch: {
        // breadcrumbs() {
        //     this.dashboard_breadcrumb = this.breadcrumbs;
        // },
        all_categories_list() {
            this.phrases_categories = this.all_categories_list;
        },
        user_type() {
            if (this.phrases_categories && this.phrases_categories.length <= 0) {
                this.getCategories();
                this.loading = true;
            }
        },
        // all_phrases_list() {
        //     this.loading = false;
        // }
    },
    computed: {
        page_name() {
            return this.$route.name;
        }
    },
    created() {
        EventBus.$on('onBackCategory', (data) => {
			this.$store.commit('store_category_selection', null);
            this.getCategories();
        });        
        EventBus.$on('onLoadingData', (data) => {
            this.loading = data;
        });
        
    },
    destroyed() {
        EventBus.$off('onBackCategory');
    },
    async mounted() {
        this.loading = true;
        // this.dashboard_breadcrumb = this.breadcrumbs;
        // this.$store.commit('store_crumbs', []);
        this.$store.commit('store_phrase_search', '');
        EventBus.$emit('onChangePage', 'Select a category');
        this.phrases_categories = this.all_categories_list;
        await this.fetchData();
        // await this.getLatestPhrase();
        this.getCategories();
        await this.getDeletedPhrase();
        await this.getCategoriesPhrases();

        EventBus.$emit('onLoadedCategory');
    },
    methods: { 
        async fetchData () {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
        },
        getCategoriesPhrases() {
            if (this.phrases_categories.length <= 0) {
                this.loading = true;
                axios.post(`/get_categories/${this.user_type == 1 ? this.user_translate_language : this.user_current_language}`).then(res => {
                    if (res.data.code == 200) {
                        this.orig_categories = res.data.data.category_list;
                        this.phrases_categories = this.orig_categories.map(category => {
                            category.phrases = res.data.data.phrase_list.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == category.id) != null && 
                                                                                        phrase.usertypes.find(type => type.id == this.user_type) != null)
                                                                        .map(phrase => {
                                                                            phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                            return phrase
                                                                        }).length;
                            return category;
                        });                    
                        this.loading = false;
                        this.$store.commit('store_orig_categories', this.orig_categories);
                        // this.$store.commit('store_phrases', res.data.data.phrase_list);
                        this.$store.commit('store_categories', this.phrases_categories.filter(cat => cat.phrases > 0));
                        
                        let latest_date = this.latest_date(res.data.data.phrase_list);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: res.data.data.phrase_list})
                        this.fetchData();
                    }
                });
            }
        },        
        getCategories() {
            if (this.phrases_categories.length > 0) { 
                this.loading = true;
                axios.post('/get_all_categories').then(res => {
                    if (res.data.code == 200) {
                        this.orig_categories = res.data.data.category_list;
                        this.phrases_categories = this.orig_categories.map(category => {
                            // category.phrases
                            category.phrases = this.all_phrases_get[0].filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == category.id) != null && 
                                                                                        phrase.usertypes.find(type => type.id == this.user_type) != null)
                                                                        .map(phrase => {
                                                                            phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                            return phrase
                                                                        }).length;
                            return category;
                        });                  
                        this.loading = false;
                        this.$store.commit('store_orig_categories', this.orig_categories);
                        this.$store.commit('store_categories', this.phrases_categories.filter(cat => cat.phrases > 0));
                    }
                });
            }
        },
        getLatestPhrase() {
            if (this.all_phrases_get.__ob__.value[0] !== undefined && this.all_phrases_get.__ob__.value[0].length > 0) {
                let latest_date = this.latest_date(this.all_phrases_get[0]);
                axios.post('/get_latest_phrases/'+ latest_date ).then(res => {
                    if (res.data.code == 200) {
                        let all_phrases = this.all_phrases_get[0];
                        res.data.data.phrase_list.forEach(element => {
                            let index = this.all_phrases_get[0].findIndex(phrase => phrase.id == element.id);
                            all_phrases = this.reassign(all_phrases, index, element);
                            if (index <= -1) {
                                all_phrases.push(element);
                            }
                        });
                        // this.$store.commit('store_phrases', all_phrases);
                        this.loading = false;
                        
                        let latest_date = this.latest_date(all_phrases);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: all_phrases.filter(phrase => phrase.published == 1)});
                        this.fetchData();
                    }
                });
                        
            }
        },
        getDeletedPhrase() {
            if (this.all_phrases_get.__ob__.value[0] !== undefined && this.all_phrases_get.__ob__.value[0].length > 0) {
                axios.post('/get_deleted_phrases').then(res => {
                    if (res.data.code == 200) {
                        let all_phrases = this.all_phrases_get[0];
                        res.data.data.phrase_list.forEach(element => {
                            all_phrases = all_phrases.filter(phrase => phrase.id != element.id);
                        });
                        this.loading = false;
                        
                        let latest_date = this.latest_date(all_phrases);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: all_phrases.filter(phrase => phrase.published == 1)});
                        this.fetchData();
                    }
                });
                        
            }
        },
    }
};
</script>
