<!-- 
    Navigation
    : layout/NavigationHeader.vue
-->

<template>
    <div class="navigation-header">
        <header>
        	<v-container fluid>
	        	<v-row class="mob align-center justify-space-between">
	        		<v-col cols="auto" md="auto" class="left">
	        			<img draggable="false" class="size-six" src="../assets/images/logo-nav.png" alt="" @click="to_url('/')">
	        		</v-col>
	        		<v-col cols="auto" md="auto" class="right">
	        			<v-row>
	        				<v-col cols="auto" class="navigation-menu-desktop" v-if="desktop_mode && !landscape_mode && desktop_menu">
	        					<v-btn v-for="(menu, i) in desktop_menu.pages_translations" :key="i" plain text @click="gotoDynamicPage(menu.page_code)">{{ menu.formatted_translations[user_current_language] }}</v-btn>
	        				</v-col>
	        				<v-col cols="auto">
	        					<v-row class="quicks">
	        						<v-col v-if="mobile_mode && user_current_language && user_translate_language">
	        							<v-btn icon @click="to_url('/categories')">
				                			<v-icon>mdi-home</v-icon>
				                		</v-btn>
				                	</v-col>
	        						<v-col v-if="mobile_mode && user_current_language && user_translate_language">
	        							<v-btn icon @click="to_url('/all-phrases')">
				                			<v-icon>mdi-magnify</v-icon>
				                		</v-btn>
				                	</v-col>
				                	<v-col v-if="user_current_language && user_translate_language">
				                		<v-btn icon  @click="drawer = !drawer">
				                		    <v-icon>mdi-menu</v-icon>
				                		</v-btn>
				                	</v-col>
	        					</v-row>
	        				</v-col>
	        			</v-row>
	        		</v-col>
	        	</v-row>
				
				<!-- <div class="navigation-language-desktop" v-if="!mobile_mode">
					<v-select append-icon="mdi-chevron-down" :items="select_type_language.languages" item-text="`${data.item.name}`" item-value="code" hide-details></v-select>
				</div> -->
	            
				<v-row class="back-nav align-center justify-space-between b-grey" v-if="mobile_mode && !isHome && !isUserSelection && !isCategory">
		            <v-col cols="auto">
		                <label class="back-btn" @click="goBack()"><v-icon>mdi-chevron-left</v-icon> {{ mapTermsCurrent('Back') }}</label>
		            </v-col>
		            <v-col cols="auto">
		            	<label class="select"> {{ mapTermsCurrent(pagetag) }}</label>
		            </v-col>
		        </v-row>  
		    </v-container>      
	    </header>

        <v-navigation-drawer
		v-model="drawer"
		fixed :style="{ height: drawer ? '100%' : '0'}"
		right 
		temporary
    >
	      	<v-list nav class="b-primary top" dense>
	        	<v-list-item-group>
				<v-row class="header-input">
					<v-col cols="12" class="text-right">
						<v-icon @click="drawer = !drawer">mdi-close</v-icon>
					</v-col>
					<v-col cols="12" :class="user_current_language">
						<label for="">{{ mapTermsCurrent('User type selected')}}</label>
					</v-col>

				<!-- User type selected > if is not Patient -->
					<v-col cols="12" v-if="user_type != 1">
						<v-select :items="select_type_language.type" item-text="name" item-value="id" solo dense class="component" hide-details
						append-icon="mdi-chevron-down" v-model="selected_user" 
						 :class="[ current_language, screen_size <= 504 || screen_size >= 769 ? 'nextline' : '']">
						<template slot="selection" slot-scope="data">
							<div :class="user_current_language" class="w-100">{{ data.item.name }}<br> {{ data.item.formatted_translations[user_current_language] }}</div>
						</template>
						<template v-slot:item="{ item }">
							<div :class="user_current_language" class="w-100">{{ item.name }} {{ item.formatted_translations[user_current_language] }}</div>
						</template>
						</v-select>
					</v-col>
				<!-- User type selected > if is Patient -->
					<v-col cols="12" v-if="user_type == 1">
						<v-select :items="select_type_language.type" item-text="name" item-value="id" solo dense class="component" hide-details
						append-icon="mdi-chevron-down" v-model="selected_user" 
						 :class="[ current_language, screen_size <= 504 || screen_size >= 769 ? 'nextline' : '']">
						<template slot="selection" slot-scope="data">
							<div :class="user_current_language" class="w-100">{{ data.item.formatted_translations[user_current_language] }}</div>
						</template>
						<template v-slot:item="{ item }">
							<div :class="user_current_language" class="w-100">{{ item.formatted_translations[user_current_language] }}</div>
						</template>
						</v-select>
					</v-col>

				<!-- Translation selected -->
					<v-col cols="12" :class="user_current_language">
						<label for="">{{ mapTermsCurrent('Translation selected') }}</label>
					</v-col>
					<v-col cols="6" class="witharrow" >
						<v-select :items="select_type_language.languages" v-if="user_type != 1" item-text="name" item-value="code" solo dense class="component language-translate" hide-details
						append-icon="mdi-chevron-down" v-model="current_language"
						>
							<template slot="selection" slot-scope="data">
								<div class="w-100">
									{{ data.item.name }}<br>
								</div>
								<div :class="user_current_language" :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" class="w-100">
									{{ data.item.translation }}
								</div>
							</template>
							<template v-slot:item="{ item }">
								{{ item.name }} <span class="pl-1" :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" >&nbsp;{{ item.translation }}</span>
							</template>
						</v-select>
						<v-select :items="select_type_language.languages" v-else disabled item-text="name" item-value="code" solo dense class="component language-selected" hide-details
						:append-icon="'false'" v-model="current_language" :class="[ current_language, screen_size <= 504 || screen_size >= 769 ? 'nextline' : '']"
						>
							<template slot="selection" slot-scope="data">
								<div class="w-100">
									{{ data.item.name }} <br>
								</div>
								<div :class="translate_language" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" class="w-100">
									<br v-if="screen_size <= 504 || screen_size >= 769 ||  translate_language != 'en'"> {{ data.item.translation === '' ? (mapTermsCurrent(data.item.name) === data.item.name ?  '' : mapTermsCurrent(data.item.name)) : data.item.translation }}
								</div>
							</template>
							<template v-slot:item="{ item }">
								{{ item.name }} <span class="pl-1" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" >&nbsp;{{ item.translation }}</span>
							</template>
						</v-select>
					</v-col>
					<v-col cols="6">
						<v-select :items="select_type_language.languages" v-if="user_type != 2" item-text="name" item-value="code" solo dense class="component language-translate" hide-details
						append-icon="mdi-chevron-down" v-model="translate_language">
							<template slot="selection" slot-scope="data" >
								<div class="w-100">
									{{ data.item.name }}<br>
								</div>
								<div :class="user_translate_language" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" class="w-100">
									{{ data.item.translation }}
								</div>
							</template>
							<template v-slot:item="{ item }">
								{{ item.name }} <span class="pl-1" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" >&nbsp;{{ item.translation }}</span>
							</template>
						</v-select>
						<v-select :items="select_type_language.languages" v-else disabled item-text="name" item-value="code" solo dense class="component language-selected" hide-details
						:append-icon="'false'" v-model="translate_language"
						>
							<template slot="selection" slot-scope="data">
								<div class="w-100 test">
									{{ data.item.name }}<br>
								</div>
								<div :class="user_translate_language" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" class="w-100">
									{{ data.item.translation === '' ? (mapTermsCurrent(data.item.name) === data.item.name ?  '' : mapTermsCurrent(data.item.name)) : data.item.translation }}
								</div>
							</template>
							<template v-slot:item="{ item }">
								{{ item.name }} <span class="pl-1" :dir="user_translate_language == 'ar' || user_translate_language == 'pr' ? 'rtl' : ''" >&nbsp;{{ item.translation }}</span>
							</template>
						</v-select>
					</v-col>
				</v-row>
	        	</v-list-item-group>
	  		</v-list>
	      	<v-list nav class="bottom" dense v-if="burger_menu">
		        <v-list-item-group>
					<v-list-item class="nav-item"  v-for="(menu, j) in burger_menu.pages_translations" :key="j" @click="gotoDynamicPage(menu.page_code)">
						<v-list-item-content :class="user_current_language" :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''">
							<v-list-item-title>{{ menu.formatted_translations[user_current_language] != '' && menu.formatted_translations[user_current_language] != undefined ? menu.formatted_translations[user_current_language] : menu.native  }}</v-list-item-title>
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list-item-group>
		  	</v-list>
			<v-row class="m25t">
				<v-col cols="12" class="svhm-com">
					<v-row class="h-100">
						<v-col cols="12" class="align-self-end text-center">
							<div class="row align-items-center text-center m15b">
								<v-col cols="6" class="align-self-center withline">
									<img draggable="false" class="size-seven melbourne" src="../assets/images/city-of-melbourne.png" alt="City of Melbourne">
								</v-col>
								<v-col cols="6" class="align-self-center">
									<img draggable="false" class="size-seven stvincent" src="../assets/images/logo-svha.png" alt="St Vincent's Hospital Melbourne - Talk to Me">
								</v-col>
							</div>
							<span>© {{ (new Date).getFullYear() }} St Vincent’s Hospital Melbourne.</span>
						</v-col>
					</v-row>	
				</v-col>
			</v-row>
    	</v-navigation-drawer>

    </div>
</template>
<script>
    export default {
        data: () => ({
            drawer: false,
			selected_user: null,
			current_language: null,
			translate_language: null,
			desktop_menu: [],
			burger_menu: [],
        }),
        watch: {
			all_pages() {
				this.desktop_menu = this.all_pages.find(page => page.code == 'main_desktop_menu');
				this.burger_menu = this.all_pages.find(page => page.code == 'burger_menu');
			},
			user_translate_language() {
				this.translate_language = this.user_translate_language;
			},
			user_current_language() {
				this.current_language = this.user_current_language;
			},
			user_type() {
				this.selected_user = this.user_type;
			},
			selected_user() {        
				let user_selection = {};
				user_selection.user_type = this.selected_user;
				user_selection.user_current_language = this.current_language == 'en' ? this.translate_language : this.current_language;
				user_selection.user_translate_language = 'en';
				if (this.selected_user == 1) { // Health Professional
					user_selection.user_current_language = 'en';
					user_selection.user_translate_language = this.translate_language == 'en' ? this.current_language : this.translate_language;;        
				}
				this.$store.commit('store_selection', user_selection);
				this.drawer = false;
			},
			translate_language() {
				let user_selection = {};
				user_selection.user_type = this.selected_user;
				user_selection.user_current_language = this.current_language;
				user_selection.user_translate_language = this.translate_language;            
				this.$store.commit('store_selection', user_selection);
				this.drawer = false;
			},        
			current_language() {
				let user_selection = {};
				user_selection.user_type = this.selected_user;
				user_selection.user_current_language = this.current_language;
				user_selection.user_translate_language = this.translate_language;            
				this.$store.commit('store_selection', user_selection);
				this.drawer = false;
			},
			drawer() {
				if (this.drawer) {
					window.scrollTo(0, 0);
				}
			}
        },
		mounted() {
			this.translate_language = this.user_translate_language;
			this.current_language = this.user_current_language;
			this.selected_user = this.user_type;
			
			this.desktop_menu = this.all_pages.find(page => page.code == 'main_desktop_menu');
			this.burger_menu = this.all_pages.find(page => page.code == 'burger_menu');
		},
		computed: {
		    isHome() {
		        return this.$route.name === 'home';
		    },
		    isUserSelection() {
		        return this.$route.name === 'userselection';
		    },
		    isCategory() {
		        return this.$route.name === 'select-category';
		    }
		},
		methods: {
			goBack() {
			    // if (this.$route.name === 'translation') {
			    //     EventBus.$emit('onBackCheckPage', 1);
			    // } else {
			        this.$router.go(-1);
			    // }
			},
			gotoDynamicPage(code) {
				let static_pages = ['all_phrases', 'find_phrase','favourites'];
				if(this.inArray(code, static_pages)) {
					if (code == 'all_phrases' && this.$route.name != 'all-phrases') {
						this.$router.push('/all-phrases');
					}
					if (code == 'find_phrase' && this.$route.name != 'select-category') {
						this.$router.push('/categories');
					}
					if (code == 'favourites') {
						if(this.$route.name != 'all-phrases') {
							// EventBus.$emit('onLoadFavourites', []);
							this.$router.push('/all-phrases?favorites=1');
							EventBus.$emit('onLoadFavourites', []);
						} else {
							EventBus.$emit('onLoadFavourites', []);
						}
					}
				} else {
					if (this.$route.name != 'page') {
						this.$router.push('/page/'+ code);
					} else {
						if (this.$route.params.pagecode != code ) {
							EventBus.$emit('onChangeDynamicPage', code);
							this.$router.push('/page/'+ code);
						}
					}
				}
			}
		}
    }
</script>