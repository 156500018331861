import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    // key: 'AIzaSyABkCc_KK8UgdOnatWtIFB0rJ-dKSbSaa0',
    // v: '1.0',
    libraries: 'places', //// If you need to use place input
  },
  autobindAllEvents: true, installComponent: true
});
 
Vue.use(VuetifyGoogleAutocomplete, {
  /*
    not used as loaded with component
    apiKey: key,
  */
  // apiKey: process.env.VUE_APP_MAP_KEY,
  vueGoogleMapsCompatibility: true,
});
