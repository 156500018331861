<!-- 
    Home
    : mobile/MobileSearch.vue
-->

<template>
    <v-row>
        <v-col cols="12" class="search b-primary text-center" :class="pagename">
            <div class="is-clipath"></div>
            <h2 class="sectitle" v-if="pagename == 'select-category'">{{ mapTermsCurrent($route.name == 'all-phrases' ? 'Search for a phrases' : 'Select a category') }}</h2> 
            <v-text-field
                solo dense class="component" :class="user_current_language" hide-details
                :placeholder="mapTermsCurrent('Search by title/keyword, e.g. interpreter')" v-model="search_text"
                :append-icon="search_text ? 'mdi-close' : 'mdi-magnify'" @click:append="search_text ? clearSearch() : clickSearch()" v-if="(['translation', 'select-category']).find((page) => page == $route.name) == undefined"
            ></v-text-field>
            <v-autocomplete :items="phrase_list" 
                :item-text="'formatted_translations['+(user_current_language).replace('-', '_')+']'"
                :item-value="'formatted_translations['+user_current_language+']'" v-if="$route.name == 'translation'"
                v-model="search_text"
                solo dense :placeholder="mapTermsCurrent('Search by title/keyword, e.g. interpreter')"
                    class="component" hide-details :append-icon="search_text ? 'mdi-close' : 'mdi-magnify'" @click:append="search_text ? clearSearch() : clickSearch()">
                <template v-slot:item="{ item }" class="autocomplete-list">
                    <v-list-item :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" link @click="gotoTranslation(item)">{{item.formatted_translations[user_current_language] !== undefined ?  item.formatted_translations[user_current_language] : item.native }}</v-list-item>
                </template>
            </v-autocomplete>
            <v-btn @click="gotoPhrases()" v-if="pagename == 'select-category'" text plain medium class="t-white"><label> {{ mapTermsCurrent('Click here for all phrases') }} </label></v-btn>
        </v-col>
    </v-row>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});
export default {
    data() {
        return {
            search_text: '',
            phrase_list: [],
            all_phrases_get: [],
        };
    },
    props: {
        pagename: null
    },
    watch: {
        async user_type() {
            await this.fetchData()
            this.loadPhrases();
        },
        search_text() {
            if (this.$route.name != 'translation') {                
                this.$store.commit('store_phrase_search', this.search_text);
            }
        },
        search_phrase_key() {
            this.search_text = this.search_phrase_key;
        }
    },
    async mounted() {
        await this.fetchData()
        this.loadPhrases();
        this.search_text = this.search_phrase_key;
    },
    methods: { 
        async fetchData() {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
        },
        gotoPhrases() {
            localStorage.setItem('current_page', 1);
            this.$router.push('/all-phrases');
        },
        gotoTranslation(phrase) {
           if (this.$route.name != 'translation') {
               this.$router.push('/translation?id=' + phrase.id);
           } else {
                EventBus.$emit('onChangeSelectedPhrase', phrase.id);
           }
        },
        loadPhrases() {
            this.phrase_list = this.all_phrases_list.filter(phrase => phrase.usertypes.find(type => type.id == this.user_type) != null);
        },
        clickSearch() {
            if (this.$route.name != 'all-phrases') {
               this.$router.push('/all-phrases');
            }
        },
        clearSearch() {
            this.search_text = '';
        }
    }
};
</script>
