<template>
	<div>
        <div class="disclaimer full-width">
            <strong> At St Vincent’s, we are concerned about ensuring we extend culturally responsive care to our Culturally and Linguistically Diverse patients.</strong>
            <br><br>
            This application gives information and asks simple questions in languages other than English.
            <br><br>
            It is only to be used in the sub-acute setting with patients or residents who might benefit from being informed of aspects of their daily care routine. 
            <br><br>
            Under no circumstances should this application be used to replace an interpreter or in situations of clinical care.
        </div>
    </div>
</template>
<script>
export default {
    name: 'disclaimer',
    data() {
        return {
        };
    },
    watch: {
    },
    mounted() {
        EventBus.$emit('onChangePage', 'Disclaimer');
    },
    methods: { 
    }
};
</script>
