<template>
    <div class="yesorno">
        
        <ul class="row content-option text-center">
            <li class="col-6"> 
                <span class="translated">{{ mapTermsTranslate('Yes') }}</span> <br>
                <v-btn class="ahref playsound-btn yes" @click="playAudio('Yes')"><v-icon class="speaker-icon">mdi-volume-high</v-icon><v-icon>check</v-icon></v-btn><br>
                <span class="translated">{{ mapTermsCurrent('Yes') }}</span>
            </li>
            <li class="col-6">
                <span class="translated">{{ mapTermsTranslate('No') }}</span> <br>
                <v-btn class="ahref playsound-btn no" @click="playAudio('No')"><v-icon class="speaker-icon">mdi-volume-high</v-icon><v-icon>close</v-icon></v-btn><br>
                <span class="translated">{{ mapTermsCurrent('No') }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'yesorno',
    data() {
        return {
            audio: null
        }
    },
    mounted() {
    },
    methods: {
        playAudio(term) {
            this.audio = new Audio(this.mapTermsAudioTranslate(term));
            this.audio ? this.audio.play() : console.log('No audio to play');
        }
    }
}
</script>