import '@babel/polyfill';
import Vue from 'vue';
import './plugins/axios';
import './plugins/google-map';
import vuetify from '@/plugins/vuetify';
import VueTelInputVuetify from "vue-tel-input-vuetify";
import store from './store';
import './mixin';
import App from './App.vue';
import router from './router';
import Components from './components';
import Toasted from 'vue-toasted';
import _ from 'lodash';

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(require('vue-moment'));

Object.keys(Components).forEach(name => {
  Vue.component(name, Components[name]);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import './assets/scss/style.scss'
// import './registerServiceWorker'
Vue.use(Toasted);