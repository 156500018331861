<template>
    <div class="number">
        <div class="content">
            <v-btn text class="custom btn-left" @click="number_count != 0 ? number_count = number_count-1: number_count"><v-icon>mdi-minus</v-icon></v-btn>
            <div class="custom input">{{ number_count }} {{ selected_option != 'None' ? mapTermsTranslate(selected_option) : '' }}</div>
            <v-btn text class="custom btn-right" @click="number_count = number_count+1"><v-icon>mdi-plus</v-icon></v-btn>
        </div>
        <ul class="content-option">
            <li :class="selected_option == 'Days' ? 'active' : ''" @click="selectOption('Days')">
                <span class="translated">{{ mapTermsTranslate('Days') }}</span> <br>
                <span class="native"> {{ mapTermsCurrent('Days') }} </span>
            </li>
            <li :class="selected_option == 'Hours' ? 'active' : ''" @click="selectOption('Hours')">
                <span class="translated">{{ mapTermsTranslate('Hours') }} </span> <br>
                <span class="native">{{ mapTermsCurrent('Hours') }} </span>
            </li>
            <li :class="selected_option == 'Minutes' ? 'active' : ''" @click="selectOption('Minutes')">
                <span class="translated">{{ mapTermsTranslate('Minutes') }} </span> <br>
                <span class="native"> {{ mapTermsCurrent('Minutes') }} </span>
            </li>
            <li :class="selected_option == 'None' ? 'active' : ''" @click="selectOption('None')">
                <span class="translated">{{ mapTermsTranslate('None') }}</span> <br>
                <span class="native">{{ mapTermsCurrent('None') }} </span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'number',
    data() {
        return {
            number_count: 5,
            selected_option: 'Days'
        }
    },
    methods: {
        selectOption(option){
            this.selected_option = option;
        }
    }
}
</script>