"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
var AUTH_TOKEN = localStorage.getItem('token_type') + " " + localStorage.getItem('api_token') || '';

axios.defaults.baseURL = process.env.VUE_APP_URL || process.env.apiUrl || 'http://talktomebackend.awdweb.com.au/api/';
if (localStorage.getItem('api_token')) {
  axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Request-Method'] = '*';

let config = {
  baseURL: process.env.VUE_APP_URL,
  timeout: 60 * 1000, // Timeout
  headers: {'Content-type': 'application/json'},
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;