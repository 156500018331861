//layout
import NavigationHeader from './layout/NavigationHeader';

import MobileSearch from './components/mobile/MobileSearch';
import CategoryList from './components/phrases/CategoryList';
import PhrasesList from './components/phrases/PhrasesList';

import GlobalSearch from './components/global/Search';

//EXTRA ICONS
import Number from './components/extraicons/Number';
import TimeIcon from './components/extraicons/TimeIcon';
import DayIcon from './components/extraicons/DayIcon';
import YesOrNo from './components/extraicons/YesorNo';


// GLOBAL
import SelectTypeLanguage from './components/global/SelectTypeLanguage';

const Components = {
    NavigationHeader,
    MobileSearch,
    CategoryList,
    PhrasesList,
    SelectTypeLanguage,
    //
    GlobalSearch,
    //EXTRA ICONS
    Number,
    TimeIcon,
    DayIcon,
    YesOrNo
};

export default Components;
