    <template>
    <div class="search global row">
        <v-col cols="12" :class="user_current_language">
            <!-- $route.name == 'all-phrases' || $route.name == 'translation' || $route.name == 'phrases' ? 'Search for a phrase' : 'Select a category' -->
            <h4>{{ mapTermsCurrent('Search for a phrase') }}</h4> 
        </v-col>
        <v-col cols="12">
            <v-text-field
                solo dense class="component" :class="user_current_language" hide-details @focus="clickSearchA()" @click="clickSearchA()"
                :placeholder="mapTermsCurrent('Search by title/keyword, e.g. interpreter')" v-model="search_text" v-if="showorigtext"
                :append-icon="search_text ? 'mdi-close' : 'mdi-magnify'" @click:append="search_text ? clearSearch() : clickSearch()"
            ></v-text-field>
            <v-autocomplete :items="phrase_list" :placeholder="aplacholder"
                :item-text="'formatted_translations['+(user_current_language).replace('-', '_')+']'"
                :item-value="'formatted_translations['+user_current_language+']'" v-if="showautocomplate" 
                v-model="search_text" :autofocus="true"
                solo dense 
                 class="component" hide-details append-icon="mdi-magnify">
                <template v-slot:item="{ item }" class="autocomplete-list">
                    <v-list-item :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" link @click="gotoTranslation(item)">{{item.formatted_translations[user_current_language] !== undefined ?  item.formatted_translations[user_current_language] : item.native }}</v-list-item>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="12">
            <v-btn text plain medium class="t-white" to="/all-phrases"><label> {{ mapTermsCurrent('Click here for all phrases') }}  </label></v-btn>
            <!-- <v-btn text plain v-if="$route.name == 'all-phrases' || $route.name == 'translation' || $route.name == 'phrases'" medium class="t-white" to="/categories"><label> {{ mapTermsCurrent('Click here for Select a category') }}  </label></v-btn> -->
        </v-col>
    </div>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});
export default {
    data() {
        return {
            search_text: '',
            phrase_list: [],
            selected_term: null,
            autocomplete_placeholder: 'Search by title/keyword, e.g. interpreter',
            showautocomplate: false,
            showorigtext: true,
            all_phrases_get: [],
        };
    },
    props: {
        pagename: null,
        aplacholder: String
    },
    created() {        
        EventBus.$on('onPhraseLoaded', (data) => {
            this.fetchData();
        });      
    },
    watch: {
        async user_type() {
            await this.fetchData();
        },
        search_text() {
            if (this.$route.name != 'translation') {                
                this.$store.commit('store_phrase_search', this.search_text);
            }
        },
        search_phrase_key() {
            this.search_text = this.search_phrase_key;
        },
        $route (to, from){
            // if (to.name == 'phrases' || to.name == 'all-phrases' || to.name == 'translation') {
            //     this.showautocomplate = false;
            //     this.showorigtext = true;                
            // } else {
                this.showautocomplate = false;
                this.showorigtext = true;
            // }
        }
    },
    async mounted() {
        await this.fetchData()
        this.search_text = this.search_phrase_key;

        if (this.$route.name == 'select-category') {
            this.$store.commit('store_phrase_search', null);
        }
        // this.selected_term = this.terminologies.find(terms => terms.native_code == (('Search by title/keyword, e.g. interpreter').toLowerCase()).replaceAll(' ', '_'));
        // console.log(this.selected_term);
    },
    methods: { 
        async fetchData() {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
            this.loadPhrases();
        },
        async clickSearchA() {
            await this.fetchData()
            // autocompletesearch
            // if (this.$route.name != 'phrases' && this.$route.name != 'all-phrases') {
                this.showautocomplate = true;
                this.showorigtext = false;
            // }
        },
        gotoTranslation(phrase) {
            this.search_text = '';
            this.showautocomplate = false;
            this.showorigtext = true;
           if (this.$route.name != 'translation') {
               this.$router.push('/translation?id=' + phrase.id);
           } else {
                EventBus.$emit('onChangeSelectedPhrase', phrase.id);
           }
        },
        loadPhrases() {
            if (this.all_phrases_get.__ob__.value[0] !== undefined && this.all_phrases_get.__ob__.value[0].length > 0) {
                this.phrase_list = this.all_phrases_get[0].filter(phrase => phrase.usertypes.find(type => type.id == this.user_type) != null);
            }
        },
        async clickSearch() {
            await this.fetchData()
            if (this.$route.name != 'all-phrases') {
               this.$router.push('/all-phrases');
            }
        },
        async clearSearch() {
            await this.fetchData()
            this.search_text = '';
        }
    }
};
</script>
