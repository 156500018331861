<template>
	<div>
        <select-type-language :data_user_types="select_type_language.type" :data_languages="select_type_language.languages"></select-type-language>
	</div>   
</template>
<script>
export default {
    name: 'userselection',
    data() {
        return {
        };
    },
    watch: {
    },

    mounted() {
        if(!this.mobile_mode) {
            this.$router.push('/');
        }
    },
    methods: { 
    }
};
</script>