<!-- 
    Select a User Type/Language
    : components/global/SelectTypeLanguage.vue
-->
<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid class="h-100">
            <div v-if="!selected_type">

                <v-row>
                    <v-col cols="12" class="text-center">
                        <h2 class="sectitle">Select user type</h2>
                    </v-col>
                </v-row>
                <v-row class="listing usertype text-center align-stretch justify-center">
                    <v-col cols="6" v-for="(item, i) in data_user_types" :key="i">
                        <div class="each" @click="selected_type = item.id" :class="[selected_type == item.id ? 'active' : '']">
                            
                            <img draggable="false" class="img-usertype" v-if="item.id == 1 && selected_type != 1" src="../../assets/images/mobile-hp.png" alt="type-icon">
                            <img draggable="false" class="img-usertype" v-if="item.id == 2 && selected_type != 2" src="../../assets/images/mobile-p.png" alt="type-icon">
                            <img draggable="false" class="img-usertype" v-if="item.id == 1 && selected_type == 1" src="../../assets/images/mobile-hp-w.png" alt="type-icon">
                            <img draggable="false" class="img-usertype" v-if="item.id == 2  && selected_type == 2" src="../../assets/images/mobile-p-w.png" alt="type-icon">
                          
                            <div class="has-content">
                                <h5>{{ item.native }}</h5>
                                <ul>
                                    <li v-for="(language, j) in data_languages" v-if="language.code !== 'en'"  :key="j"> {{ item.formatted_translations[language.code] }} </li>
                                </ul>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="8" lg="12" class="btn-action" v-if="false" :class="selected_type ? 'clicked' : ''">
                        <v-btn class="ahref solid full" @click="selected_user_type = selected_type"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-if="selected_type" class="select-language">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h2 class="sectitle">Select a language</h2>
                    </v-col>
                </v-row>
                <v-row class="listing language text-center align-stretch justify-center">
                    <v-col cols="6"  v-for="(item, i) in data_languages"
                    :key="i" >
                        <div class="each" @click="selected_language = item.code; next()" :class="[!item.translation ? 'd-flex align-center justify-center' : '', selected_language == item.code ? 'active' : '']">
                            <h5 :class="item.code" v-if="item.translation">{{ item.translation }}</h5>
                            <p v-if="item.name">{{ item.name }}</p>
                        </div>
                    </v-col>
                    <v-col cols="12" class="btn-action clicked" v-if="false">
                        <v-row>
                            <v-col cols="6">
                                <v-btn class="ahref solid full inactive" @click="selected_user_type = ''"><v-icon>mdi-chevron-left</v-icon></v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn class="ahref solid full" @click="next()"><v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </section>
</template>
<script>
export default {
    name: 'SelectTypeLanguage',
    data: () => ({
        selected_type: '',
        selected_language: 'en'
    }),
    props: {
        data_user_types: Array,
        data_languages: Array
    },
    mounted() {
        if (this.user_type && this.user_current_language && this.user_translate_language) {
            this.$router.push('/categories');
        }
    },
    computed: {
    },
    methods: {
        next() {
            let user_selection = {};
            user_selection.user_type = this.selected_type;
            user_selection.user_current_language = this.selected_language;
            user_selection.user_translate_language = 'en';
            if (this.selected_type == 1) { // Health Professional
                user_selection.user_current_language = 'en';
                user_selection.user_translate_language = this.selected_language;
            }
            
            this.$store.commit('store_selection', user_selection);
            this.$router.push('/categories');
        }
    },
};
</script>