import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#0067B1',
    secondary: '#402987',
    tertiary: '#4C5052',
    accent: '#1E3581',
    error: '#b71c1c'
  }
})
const vuetify = new Vuetify({
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        primary: '#0067B1',
        secondary: '#402987',
        tertiary: '#4C5052',
        dark: '#131523',
        white: '#FFFFFF',
        accent: '#1E3581',
        error: '#F0142F',
        danger: '#F0142F',
        warning: '#FFB900',
        success: '#00A887',
        failed: '#DD0048',
        green: '#70C041',
      },
    },
  },
})


export default new Vuetify()
