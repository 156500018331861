import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
	  user_type: null,
	  user_current_language: 'en',
	  user_translate_language: 'en',
	  select_type_language: null,
	  all_phrases_list: [],
	  all_categories_list: [],
	  selected_phrase_category: null,
	  global_orig_categories : [],
	  terminologies : null,
	  banner_images: [],
	  all_pages: [],
	  all_favorites: [],
	//   breadcrumbs: [],
	  search_phrase_key: '',
	  expiryDate: '',
  },
  getters: {
    isExpired: state => (new Date(state.expiryDate) < new Date()),
  },
  mutations: {
	store_expiry_date: (state, data) => {
		// Add your delta for expiry. I am expiring in two minutes.
		let minutes = 30;
		let afterSomeMinutes = new Date(new Date().getTime() + minutes * 60000);
		// Set the state
		state.expiryDate = afterSomeMinutes;
	},
    store_selection (state, data)
	{
		state.user_type 				= data.user_type;
		state.user_current_language 	= data.user_current_language;
		state.user_translate_language 	= data.user_translate_language;

		localStorage.setItem('user_type', data.user_type);
		localStorage.setItem('user_current_language', data.user_current_language);
		localStorage.setItem('user_translate_language', data.user_translate_language);
    },
	store_type_languages (state, data) {
		state.select_type_language = data;
	},
	store_category_selection (state, data) {
		state.selected_phrase_category = data;
	},
	store_phrases (state, data) {
		state.all_phrases_list = data;
	},
	store_categories (state, data) {
		state.all_categories_list = data;
	},
	store_orig_categories (state, data) {
		state.global_orig_categories = data;
	},
	store_terms (state, data) {
		state.terminologies = data;
	},
	store_images(state, data) {
		state.banner_images = data;
		localStorage.setItem('banner_images', JSON.stringify(data));
	},
	store_pages(state, data) {
		state.all_pages = data;
	},
	store_favorites(state, data) {
		state.all_favorites = data;
	},
	// store_crumbs(state, data) {
	// 	state.breadcrumbs = data;
	// },
	store_phrase_search(state, data) {
		state.search_phrase_key = data;
	}
  },
  actions: {

  }
})
