<template>
    <div class="time" >
        <ul class="content-option top row justify-center right-align">
            <!-- <li>
                <span class="translated">{{ mapTermsTranslate('Day') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsTranslate('Hour') }} </span>
            </li> -->
            <li class="col-auto">
                <span class="translated">{{ mapTermsTranslate('Time') }}</span>
            </li>
        </ul>
        <div class="picker" :class="user_translate_language">
            <div class="picker-window"></div>
            <!-- <ul class="picker-day" id="timepickerday">
                <li class="translated" v-for="date in all_dates" :key="date">
                    <span :class="user_translate_language">{{ getDateTranslate(date, 'ddd') }} {{ getDateTranslate(date, 'MMM') }}  </span> {{ getDateTranslate(date, 'D') }}
                    -- {{ moment(date).format(format) }} --
                </li>
            </ul> -->
            <ul class="picker-hour" id="timepickerhour">
                <li v-for="hr in 12" :key="hr">{{ hr }}</li>
            </ul>
            <ul class="picker-minute" id="timepickerminute">
                <li>00</li>
                <li v-for="min in 59" :key="min">{{ min <= 9 ? '0' + min : min }}</li>
            </ul>
            <ul class="picker-am" id="timepickeram" v-if="user_type == 2">
                <li :class="user_translate_language">{{ mapTermsCurrent('AM') }} </li>
                <li :class="user_translate_language">{{ mapTermsCurrent('PM') }}</li>
            </ul>
            <ul class="picker-am" id="timepickeram" v-if="user_type == 1">
                <li :class="user_translate_language">{{ mapTermsTranslate('AM') }} </li>
                <li :class="user_translate_language">{{ mapTermsTranslate('PM') }}</li>
            </ul>
        </div>
        <ul class="content-option row justify-center right-align">
            <!-- <li>
                <span class="translated">{{ mapTermsCurrent('Day') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsCurrent('Hour') }} </span>
            </li> -->
            <li class="col-auto">
                <span class="translated">{{ mapTermsCurrent('Time') }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: 'timeicon',
    data() {
        return {
            all_dates: [],
        }
    },
    watch: {
        all_dates() {
        }
    },
    mounted() {
        this.all_dates = this.getDaysBetweenDates(moment(this.current_year+'-01-01'),moment(this.current_year+'-12-31'));
        setTimeout(() => this.scrollToDate(), 500);
        
    },
    methods: {
        getDateTranslate(date, format) {
            return this.mapTermsTranslate(moment(date).format(format));
        },
        scrollToDate() {
            let orig = true;
            if (this.screen_size <= 1080 && this.screen_size >= 993) {
                orig = false;
            }

            var now = new Date();
            var start = new Date(now.getFullYear(), 0, 0);
            var diff = now - start;
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            
            let pickerday = document.getElementById('timepickerday');
            if (pickerday) {
                pickerday.scrollTop = day * (orig ? 46.9 : 33.9);

                let pickerhour = document.getElementById('timepickerhour');
                let hours = now.getHours() <= 12 ? now.getHours() : now.getHours() - 12;
                setTimeout(() => pickerhour.scrollTop = (orig ? 44.3 : 33.3) * hours, 500);                

                let pickerminute = document.getElementById('timepickerminute');
                setTimeout(() => pickerminute.scrollTop = (orig? 45.1 : 35.5) * now.getMinutes(), 500);
                
                let pickerampm = document.getElementById('timepickeram');
                setTimeout(() => pickerampm.scrollTop = 47 * (now.getHours() >= 12 ? 2 : 0), 500);
            }

        }
    }
}
</script>