import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from './views/Dashboard.vue'
import Home from './views/Home.vue'
import UserSelection from './views/mobile/UserSelection.vue'
import SelectCategory from './views/mobile/SelectCategory.vue'
import Phrases from './views/mobile/Phrases.vue'
import Translation from './views/mobile/Translation.vue'
import AllPhrases from './views/mobile/AllPhrases.vue'
import Faq from './views/mobile/Faq.vue'
import Disclaimer from './views/mobile/Disclaimer.vue'
import Page from './views/Page.vue'
import PageNotFound from './views/PageNotFound.vue'

// User

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{ 
			path: '/', component: Dashboard, 
				children: [
					{ path: '', name: 'home', component: Home },
					{ path: '/dashboard', name: 'userselection', component: UserSelection },
					{ path: '/categories', name: 'select-category', component: SelectCategory },
					{ path: '/phrases', name: 'phrases', component: Phrases },
					{ path: '/translation', name: 'translation', component: Translation },
					{ path: '/all-phrases', name: 'all-phrases', component: AllPhrases },
					{ path: '/faqs', name: 'faqs', component: Faq },
					{ path: '/disclaimer', name: 'disclaimer', component: Disclaimer },
					{ path: '/page/:pagecode', name: 'page', component: Page },
					// { path: '*', name: 'page-not-found', component: PageNotFound }
				],
		},		
		{ path: '*', name: 'page-not-found', component: PageNotFound }
	],
	scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})