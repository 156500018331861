<!-- 
    Home
    : views/Home.vue
-->
<template>
	<section class="element" :class="[$route.name]">
		<v-container fluid class="h-100">
			<v-row class="align-stretch h-100" :class="[!mobile_mode ? 'b-dark-primary' : '']">
				<v-col cols="12" sm="5" md="4" class="splash">
					<v-row class="h-100 no-gutters">
						<v-col cols="12" class="banner">	
							<img v-if="mobile_mode" draggable="false" src="../assets/images/intro-strip-cropped.png" alt="St Vincent's Hospital Melbourne - Talk to Me">
							<img v-if="tabletp_mode && !mobile_mode" draggable="false" src="../assets/images/intro-strip-cropped.png" alt="St Vincent's Hospital Melbourne - Talk to Me">
							<img v-if="desktop_mode && !mobile_mode && !tabletp_mode" draggable="false" src="../assets/images/intro-strip.png" alt="St Vincent's Hospital Melbourne - Talk to Me">
						</v-col>
						
						
						<v-col cols="12" class="logo" v-if="!landscape_mode">
							<v-row class="h-100">
								<v-col cols="12" class="align-self-center text-center">
									<img v-if="mobile_mode" draggable="false" class="app size-four"  src="../assets/images/logo.png" alt="Talk to Me" style="max-width: 150px;">
									<img v-if="!mobile_mode" draggable="false" class="app size-six" src="../assets/images/logo-h.png" alt="Talk to Me" style="max-width: 300px;">
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="svhm-com">
							<v-row class="h-100">
								<v-col cols="12" class="align-self-end text-center">
									<div class="row align-items-center text-center m15b">
										<v-col cols="6" class="align-self-center withline">
											<img draggable="false" class="size-seven melbourne" src="../assets/images/city-of-melbourne.png" alt="City of Melbourne">
										</v-col>
										<v-col cols="6" class="align-self-center">
											<img draggable="false" class="size-seven stvincent" src="../assets/images/logo-svha.png" alt="St Vincent's Hospital Melbourne - Talk to Me">
										</v-col>
									</div>
									<strong v-if="!landscape_mode">© {{ (new Date).getFullYear() }} St Vincent’s Hospital Melbourne.</strong>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="11" sm="7" md="8" v-if="landscape_mode">
					<v-row class="h-100">
						<v-col cols="12" class="logo">
							<v-row class="h-100">
								<v-col cols="12" class="align-self-center text-center">
									<img v-if="mobile_mode" draggable="false" class="app size-four"  src="../assets/images/logo.png" alt="Talk to Me" style="max-width: 150px;">
									<img v-if="!mobile_mode" draggable="false" class="app size-six" src="../assets/images/logo-h.png" alt="Talk to Me" style="max-width: 300px;">
								</v-col>
								<v-col cols="12" class="align-self-end text-center">
									<strong>© {{ (new Date).getFullYear() }} St Vincent’s Hospital Melbourne.</strong>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="11" sm="7" md="8" class="select-user-type" v-if="!mobile_mode">
					<v-row class="align-center justify-center h-100">
						<v-col cols="12" lg="8" v-if="select_type_language">
							<select-type-language :data_user_types="select_type_language.type" :data_languages="select_type_language.languages"></select-type-language>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>


<script>
	export default {
	    name: 'home',
	    data() {
	        return {
				countdown: 3
	        };
	    },
	    watch: {
			mobile_mode() {
				if (this.mobile_mode == true) {
					this.countDownTimer();
				}
			},
			countdown() {
				if(this.countdown == 0) {
	                this.$router.push('/dashboard');
				}
			}
	    },
		mounted() {
			// this.getTerms();
			this.getTypeLanguages();
			// this.getImages();
			// this.getPages();
		},
	    methods: {  
			countDownTimer() {
				if(this.countdown > 0) {
					setTimeout(() => {
						this.countdown -= 1
						this.countDownTimer()
					}, 1000)
				}
			},	
			getTypeLanguages() {
				if(!this.select_type_language) {
					axios.post('/get_usertypes_and_languages').then(res => {
						if (res.data.code == 200) {
							let select_type_language = {};
							select_type_language.type = res.data.data.usertypes;
							select_type_language.languages = res.data.data.languages;
							
							this.$store.commit('store_type_languages', select_type_language);
						}
					});
				}
			}
	    }
	};
</script>