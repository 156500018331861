<template>
    <div>
        <div class="phrases-category-list category">
            <v-list dense class="py-0 navlist row" link>
                <div class="col-md-6 col-12" v-for="(category, i) in paginate_category_list[current_page]" :key="i">
                    <v-list-item class="nav-item" @click="selectCategory(category)">
                        <v-list-item-content :class="user_current_language == 'ar' || user_current_language == 'pr' ? 'text-right' : '' ">
                            <v-list-item-title :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''">{{ category.formatted_translations[user_current_language] !== undefined ? category.formatted_translations[user_current_language] : category.description }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>            
                </div>
            </v-list>
                        
            <div class="col-12 filters d-flex-reverse pagination" v-if="page > 1">
                <label :class="current_page == 1 ? 'active' : ''" @click="current_page = 1"><v-icon>mdi-chevron-double-left</v-icon></label>
                <label @click="current_page > 1 ? current_page = current_page - 1 : ''"><v-icon>mdi-chevron-left</v-icon></label>
                <label >{{ current_page }}/{{ page }}</label>
                <label @click="current_page < page ? current_page = current_page + 1 : ''"><v-icon>mdi-chevron-right</v-icon></label>
                <label :class="current_page == page ? 'active' : ''" @click="current_page = page"><v-icon>mdi-chevron-double-right</v-icon></label>
            </div>
        </div>        
        <div class="row" v-if="phrases_list && phrases_list.length > 0">
            <div class="col-12"> <h4>Phrases</h4> </div>
        </div>
        <phrases-list v-if="phrases_list && phrases_list.length > 0" :data="phrases_list" :filter="'all'"></phrases-list>
    </div>
</template>
<script>
export default {
    data() {
        return {
            category_list: [],
            filtered_category_list: [],
            dashboard_breadcrumb: [],
            phrases_list: [],
            search_text: '',
            paginate: 24,
            page: 0,
            paginate_category_list: [],
            current_page: 1,
        };
    },
    props: {
        data: Array,
    },
    watch: {
        data() {
            this.category_list = this.data;
            this.filtered_category_list = this.category_list;
            this.loadCategories();
        },
        // breadcrumbs() {
        //     this.dashboard_breadcrumb = this.breadcrumbs;
        // },
        search_text() {
           this.loadCategories();
        },
        search_phrase_key() {
            this.search_text = this.search_phrase_key;
        },
        all_categories_list() {
            this.category_list = this.all_categories_list;
        },
    },
    mounted() {
        this.category_list = this.all_categories_list;
        this.filtered_category_list = this.category_list;
        this.loadCategories();
		this.$store.commit('store_category_selection', null);
        this.search_text = this.search_phrase_key;
        // this.dashboard_breadcrumb = this.breadcrumbs;
        // this.dashboard_breadcrumb ? this.dashboard_breadcrumb = this.dashboard_breadcrumb.filter(crumb => crumb.link != 'select-category'): '';
        // this.$store.commit('store_crumbs', this.dashboard_breadcrumb);
    },
    methods: { 
        selectCategory(category) {
			this.$store.commit('store_phrase_search', '');
			this.$store.commit('store_category_selection', category);
            // EventBus.$emit('onClickPhrasesCategories', {});
            // if(!(this.dashboard_breadcrumb.find(crumb => crumb.link == 'select-category'))) {
                // EventBus.$emit('onRecordCrumbs', { name: 'Select a category', link: 'select-category' });
                // EventBus.$emit('onRecordCrumbs', { native_code: 'Search for a phrase' ,name: this.mapTermsCurrent('Search for a phrase'), link: 'phrases' });
            // }
            console.log(category.subcategories !== undefined && category.subcategories.length > 0);
            if (category.subcategories !== undefined && category.subcategories.length > 0) {
                this.category_list = category.subcategories.map((category, index) => {
                                            this.page = Math.ceil(this.all_categories_list.length / this.paginate);
                                            category.page_number = this.getPageNumber(index + 1);
                                            return category;
                                        });
                this.current_page = 1;
                this.page = Math.ceil(this.category_list.length / this.paginate);
                this.paginate_category_list = _.groupBy(this.category_list, 'page_number');
                
                this.phrases_list = this.all_phrases_list.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == category.id) != null)
                                                         .map(phrase => {
                                                                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                return phrase
                                                         });

                EventBus.$emit('onChangePage', 'Select a subcategory');
                EventBus.$emit('onRecordCrumbs', { name: category.formatted_translations[this.user_current_language], category: true, link: category });                
            } else {
                this.$router.push('/phrases');
                localStorage.setItem('current_page', 1);
            }
        },
        loadCategories() {
            this.filtered_category_list = this.category_list.map((category, index) => {
                                            this.page = Math.ceil(this.data.length / this.paginate);
                                            category.page_number = this.getPageNumber(index + 1);
                                            return category;
                                        });
            if (this.search_text && this.search_text != '' && typeof this.search_text !== undefined) {
                this.filtered_category_list = this.filtered_category_list.filter(category => {
                                                                            if (typeof category.formatted_translations[this.user_current_language] !== undefined &&
                                                                                category.formatted_translations[this.user_current_language]) {
                                                                                return category.formatted_translations[this.user_current_language].toLowerCase().indexOf(this.search_text.toLowerCase()) > -1;
                                                                            } else {
                                                                                return category.description.toLowerCase().indexOf(this.search_text.toLowerCase()) > -1
                                                                            }
                                                                        });
            }
            if (this.selected_phrase_category != null) {
                this.filtered_category_list = this.filtered_category_list.filter(category => category.parent_id == this.selected_phrase_category.id);
            }
            this.page = Math.ceil(this.filtered_category_list.length / this.paginate);            
            this.paginate_category_list = _.groupBy(this.filtered_category_list, 'page_number');
        },
        getPageNumber(index) {
            for (let i = 1; i <= this.page; i++) {
                if (index >= i && index <= (i * this.paginate)) {
                    return i;
                }
            }
        },
    }
};
</script>
