
<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid>
            <mobile-search :pagename="page_name" v-if="mobile_mode"></mobile-search>
            <v-row>
                <v-col cols="12" class="filters d-flex-reverse" :class="user_current_language">
                    <p><label @click="filter = 'all'; $router.replace('all-phrases')" class="select" :class="filter == 'all' ? 'selected' : '' "> {{ mapTermsCurrent('Click here for all phrases') }}</label> |
                    <label @click="filter='favorites'; $router.replace('all-phrases?favorites=1')" class="select" :class="filter == 'favorites' ? 'selected' : ''" > {{ mapTermsCurrent('Favourites') }}</label> </p>
                </v-col>
                <phrases-list :data="all_phrase" :filter="filter"></phrases-list>
            </v-row>
        </v-container>
    </section>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});

export default {
    name: 'all-phrases',
    data() {
        return {
            filter: 'all',
            all_phrase: [],
            all_phrases_get: [],
        };
    },
    watch: {
        all_phrases_list() {
            this.loadPhrases();
        },
        user_type() {
            this.loadPhrases();
        },
        // filter() {
        //     this.loadPhrases();
        // }
    },
    created() {
        EventBus.$on('onLoadFavourites', (data) => {
            this.filter = 'favorites';
        });
    },
    computed: {
        page_name() {
            return this.$route.name;
        }
    },
    async mounted() {
        EventBus.$emit('onChangePage', 'Search for a phrase');
        await this.fetchData();
        this.loadPhrases();
        if (this.$route.query.favorites == 1) {
            this.filter = 'favorites';
        }
    },
    methods: { 
        async fetchData () {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
        },
        loadPhrases() {
            this.all_phrase = this.all_phrases_get[0].map((phrase, index) => {
                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                return phrase;
            });
        }
    }
};
</script>
