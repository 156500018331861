<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid>
            <v-row align="center">
                <v-col>
                    <v-card class="component">
                        <v-card-text class="pa-5">
                            <h4>{{ mapTermsCurrent("404 Page not found") }}</h4>

                            <br>
                            <em><a href="/" style="text-decoration: underline !important">{{ mapTermsCurrent("Go back to home") }}</a></em>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
export default {
    name: 'page-not-found',
    data() {
        return {
        };
    },
    methods: {
        mounted() {
            EventBus.$emit('onChangePage', 'Page not found');
        }
    }
};
</script>
