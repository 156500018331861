<!-- 
    Translating a Phrase
    : views/mobile/Translation.vue
-->
<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid v-if="phrase_selected">
            <v-row class="language b-secondary align-center" v-if="user_type == 2">
                <div class="col-6 witharrow">
                    <v-select :items="select_type_language.languages" item-text="name" item-value="code" solo dense class="component language-translate" hide-details
                         v-model="current_language" append-icon="mdi-chevron-down" :class="screen_size <= 504 || screen_size >= 769 ? 'nextline' : ''"
                    >
                    <template slot="selection" slot-scope="data">
    					<div class="w-100">
                            {{ data.item.name }} 
                            <br v-if="screen_size <= 768">
                            <span :dir="data.item.code == 'ar' || data.item.code == 'pr' ? 'rtl' : ''">{{ data.item.translation }}</span>
                        </div>
                    </template>
                    <template v-slot:item="{ item }">
    					<div>
                            {{ item.name }} 
                            <span :dir="item.code == 'ar' || item.code == 'pr' ? 'rtl' : ''">{{ item.translation }}</span>
                        </div>
                    </template>
                    </v-select>
                </div>
                <div class="col-6">
                    <div class="current-lang">
                        <h5>{{ select_type_language.languages.find(lang => lang.code == user_translate_language ).name }} <br v-if="screen_size <= 768">
                            {{ select_type_language.languages.find(lang => lang.code == user_translate_language ).name ===  mapTermsCurrent(select_type_language.languages.find(lang => lang.code == user_translate_language ).name) ? '' : mapTermsCurrent(select_type_language.languages.find(lang => lang.code == user_translate_language ).name) }}</h5>
                    </div>
                </div>
            </v-row>
            <div v-if="!loading_phrase">
                <v-row class="language b-secondary align-center" v-if="user_type == 1">
                    <div class="col-6 witharrow">
                        <div class="current-lang">
                            <h5 :class="user_current_language">{{ select_type_language.languages.find(lang => lang.code == user_current_language ).name }} <br v-if="screen_size <= 768">
                                {{ select_type_language.languages.find(lang => lang.code == user_current_language ).name === mapTermsCurrent(select_type_language.languages.find(lang => lang.code == user_current_language ).name) ? '' :
                                mapTermsCurrent(select_type_language.languages.find(lang => lang.code == user_current_language ).name)  }}</h5>
                        </div>
                    </div>            
                    <div class="col-6">
                        <v-select :items="select_type_language.languages" item-text="name" item-value="code" solo dense class="component language-translate" hide-details
                            append-icon="mdi-chevron-down" v-model="translate_language" :class="screen_size <= 768 ? 'nextline' : ''"
                        >
                        <template slot="selection" slot-scope="data">
                            <div class="w-100">
                                {{ data.item.name }} 
                                <br v-if="screen_size <= 768">
                                <span :dir="data.item.code == 'ar' || data.item.code == 'pr' ? 'rtl' : ''">{{ data.item.translation }}</span>
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <div>
                                {{ item.name }} 
                                <span :dir="item.code == 'ar' || item.code == 'pr' ? 'rtl' : ''">{{ item.translation }}</span>
                            </div>
                        </template>
                        </v-select>
                    </div>
                </v-row>
                <v-row class="translation">
                    <v-col cols="11" md="6" v-if="current_language" class="phrase translate" :class="current_language == 'ar' || current_language == 'pr' ? 'text-right' : 'text-md-center'">
                        <div :dir="current_language == 'ar' || current_language == 'pr' ? 'rtl' : ''" >{{ phrase_selected.formatted_translations[current_language] !== undefined ?                    
                            (phrase_selected.formatted_translations[current_language] != '' ?  phrase_selected.formatted_translations[current_language] : phrase_selected.formatted_translations[translate_language])
                            : 'No current translation available'}}</div>
                    </v-col>
                    <v-col :dir="translate_language == 'ar' || translate_language == 'pr' ? 'rtl' : ''"  cols="12" md="6" v-if="translate_language" class="phrase translated" :class="translate_language == 'ar' || translate_language == 'pr' ? 'text-right' : 'text-md-center'">
                        {{ phrase_selected.formatted_translations[translate_language] !== undefined ?
                        (phrase_selected.formatted_translations[translate_language] != '' ? phrase_selected.formatted_translations[translate_language] : phrase_selected.formatted_translations[current_language] ) 
                        : 'No current translation available'}}
                    </v-col>
                </v-row>

                <v-row class="translation-actions align-center" :class="phrase_selected.image ? 'withimage' : ''">
                    <v-col cols="12" class="order-2 pt-0"  :class="[selected_icon != '' || phrase_selected.image != null ? 'col-md-6' : '', phrase_selected.image != null ? 'order-md-2' : 'order-md-1']">
                        <div class="w-100 audio-translation" :class="[phrase_selected.formatted_audio_paths && phrase_selected.formatted_audio_paths[translate_language] == null ? 'disabled' : '']">
                            <div class="audio" @click="playAudio()" :class="audio_playing ? 'playing-audio' : ''">
                                <v-icon>mdi-volume-high</v-icon>
                            </div>
                        </div>
                        <div v-if="phrase_selected.image" class="w-100 text-center mt-10">
                            <v-btn plain text @click="addToFavorites(phrase_selected.id)">
                                <v-icon :class="favorite_phrase ? 'added' : '' ">mdi-heart</v-icon> {{ favorite_phrase ? mapTermsCurrent('Added to favourites') : mapTermsCurrent('Add to favourites') }}
                            </v-btn>
                        </div>
                        <div class="icon-container text-center" :class="[selected_icon ? 'activate' : '']" v-if="phrase_selected.image">
                            <br>
                            <div class="icon-actions" :class="'selected-'+selected_icon">
                                <number v-if="selected_icon == 'number'" ></number>
                                <time-icon v-if="selected_icon == 'time'"></time-icon>
                                <day-icon v-if="selected_icon == 'day'"></day-icon>
                                <yes-or-no  v-if="selected_icon == 'yesorno'"></yes-or-no>
                            </div>
                            <br>
                        </div>
                    </v-col>
                    <div class="col-md-6 col-12 text-center icon-container order-1 " :class="[selected_icon || phrase_selected.image ? 'activate' : '', phrase_selected.image != null ? 'order-md-1' : 'order-md-3']">
                        <div class="w-100 text-center" v-if="phrase_selected.image">
                            <br>
                            <v-img class="translation-img" :src="phrase_selected.image"
                                max-width="80%"
                            ></v-img>
                        </div>
                        <br>
                        <div v-if="!phrase_selected.image" class="icon-actions" :class="'selected-'+selected_icon">
                            <number v-if="selected_icon == 'number'" ></number>
                            <time-icon v-if="selected_icon == 'time'"></time-icon>
                            <day-icon v-if="selected_icon == 'day'"></day-icon>
                            <yes-or-no  v-if="selected_icon == 'yesorno'"></yes-or-no>
                        </div>           
                    </div>
                    <div v-if="phrase_selected.image == null" class="col-12 add-to-favorites m15t text-center order-3" :class="selected_icon != '' ? 'col-md-6' : 'col-12' ">
                        <div>
                            <v-btn plain text @click="addToFavorites(phrase_selected.id)">
                                <v-icon :class="favorite_phrase ? 'added' : '' ">mdi-heart</v-icon> {{ favorite_phrase ? mapTermsCurrent('Added to favourites') : mapTermsCurrent('Add to favourites') }}
                            </v-btn>
                        </div>
                    </div>
                    <!-- <div class="floating-icons">
                        <ul>
                            <li :class="selected_icon == 'number' ? 'active' : ''"  @click="selectIcon('number')"><i class="fas fa-hashtag"></i></li>
                            <li :class="selected_icon == 'time' ? 'active' : ''"  @click="selectIcon('time')"><i class="far fa-clock"></i></li>
                            <li :class="selected_icon == 'day' ? 'active' : ''"  @click="selectIcon('day')"><i class="far fa-calendar-alt"></i></li>
                            <li :class="selected_icon == 'yesorno' ? 'active' : ''"  @click="selectIcon('yesorno')" class="yesorno"><i class="fas fa-check"></i><i class="fas fa-times"></i></li>
                        </ul>
                    </div> -->
                </v-row>
            </div>
            <div v-else>
                <v-row class="align-center custom-loading">
                    <v-col cols="12" class="h-100 content">
                        <img v-bind:src="require('../../assets/images/loading.gif')"/>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </section>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});

export default {
    name: 'phrases',
    data() {
        return {
            added_to_favorites: false,
            selected_icon: '',
            phrase_selected: {},
            phrase_selected_id: null,
            translate_language: null,
            current_language: null,
            audio_selected: '',
            get_favorites: [],
            audio_playing: false,
            timer: null,
            minHeight: null,
            all_phrases_get: [],
            favorite_phrase: false,
            loading_phrase: false,
        };
    },
    created() {
        EventBus.$on('onChangeSelectedPhrase', (id) => {
            this.$router.push({path: this.$route.name, query: {id:id}});
            this.fetchData();
            this.getLatestPhrase(id);
            this.viewCountPhrase(id);
            this.audio_playing = false;
            if (this.timer) clearTimeout(this.timer);
            this.audio_selected ? this.audio_selected.pause() : '';
            this.phrase_selected_id = id;
            this.phrase_selected =  this.all_phrases_get[0].find(phrase => phrase.id == id);
            this.phrase_selected.favorite = this.all_favorites.find(favorite => favorite == this.phrase_selected.id) ? true : false;
            this.favorite_phrase = this.all_favorites.find(favorite => favorite == this.phrase_selected.id) ? true : false;
            this.audio_selected = new Audio(this.phrase_selected.formatted_audio_paths[this.translate_language]);
            this.selected_icon = this.phrase_selected.trigger_action != null ? this.phrase_selected.trigger_action : '';
        });
    },
    destroyed() {
        EventBus.$off('onChangeSelectedPhrase');
    },
    watch: {
        user_translate_language() {
            this.translate_language = this.user_translate_language;
        },
        user_current_language() {
            this.current_language = this.user_current_language;
        },
        all_favorites() {
            this.get_favorites = this.all_favorites;
        },
        translate_language() {
            let user_selection = {};
            user_selection.user_type = this.user_type;
            user_selection.user_current_language = this.current_language;
            user_selection.user_translate_language = this.translate_language;
            this.audio_playing = false;
            if (this.timer) clearTimeout(this.timer);
            this.audio_selected ? this.audio_selected.pause() : '';
            this.audio_selected = new Audio(this.phrase_selected.formatted_audio_paths[this.translate_language]);
            this.$store.commit('store_selection', user_selection, function() {
                EventBus.$emit('onChangePage', 'Translate a phrase');
            });
        },
        current_language() {
            let user_selection = {};
            user_selection.user_type = this.user_type;
            user_selection.user_current_language = this.current_language;
            user_selection.user_translate_language = this.translate_language;    
            this.audio_playing = false;
            if (this.timer) clearTimeout(this.timer);
            this.audio_selected ? this.audio_selected.pause() : '';
            this.audio_selected = new Audio(this.phrase_selected.formatted_audio_paths[this.translate_language]);
            this.$store.commit('store_selection', user_selection, function() {
                EventBus.$emit('onChangePage', 'Translate a phrase');
            });
        },
        user_type() {
            if (this.phrase_selected.usertypes.find(type => type.id == this.user_type) == undefined) {
                this.$router.push('/categories');
            }
        }
    },
    async mounted() {
        this.loading_phrase = true;
        await this.fetchData();
        await this.getLatestPhrase();
        EventBus.$emit('onChangePage', 'Translate a phrase');
        this.phrase_selected = this.all_phrases_get[0].find(phrase => phrase.id == this.$route.query.id);
        this.viewCountPhrase(this.$route.query.id);
        this.phrase_selected.favorite = this.all_favorites.find(favorite => favorite == this.phrase_selected.id) ? true : false;
        this.favorite_phrase = this.all_favorites.find(favorite => favorite == this.phrase_selected.id) ? true : false;
        this.phrase_selected_id = this.$route.query.id;
        if (this.phrase_selected.formatted_audio_paths[this.translate_language] !== undefined) {
            this.audio_selected = new Audio(this.phrase_selected.formatted_audio_paths[this.translate_language]);
        }
        this.translate_language = this.user_translate_language;
        this.current_language = this.user_current_language;
        this.get_favorites = this.all_favorites; 
        this.selected_icon = this.phrase_selected.trigger_action != null ? this.phrase_selected.trigger_action : '';

        if (window.innerHeight <= 820) {
            this.minHeight = '800px';
        }
        this.loading_phrase = false;
        
    },
    methods: { 
        async fetchData () {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
        },
        async getLatestPhrase(phrase_id = null) {
            phrase_id = phrase_id ? phrase_id : this.$route.query.id;
            if (this.all_phrases_get.__ob__.value[0] !== undefined && this.all_phrases_get.__ob__.value[0].length > 0) {
                await axios.post('/get_phrase/'+ this.$route.query.id ).then(res => {
                    if (res.data.code == 200) {
                        let all_phrases = this.all_phrases_get[0];
                        res.data.data.phrase_list.forEach(element => {
                            let index = this.all_phrases_get[0].findIndex(phrase => phrase.id == element.id);
                            all_phrases = this.reassign(all_phrases, index, element);
                            if (index <= -1) {
                                all_phrases.push(element);
                            }
                        });
                        // this.$store.commit('store_phrases', all_phrases);
                        this.loading = false;
                        
                        let latest_date = this.latest_date(all_phrases);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: all_phrases.filter(phrase => phrase.published == 1)});
                        this.fetchData();
                    }
                });
                        
            }
        },
        playAudio() {
            if (!this.audio_playing) {
                this.audio_selected ? this.audio_selected.play() : console.log('No audio to play');
                this.audio_playing = true;
                this.timer = setTimeout(() => this.audio_playing = false, Math.ceil(this.audio_selected.duration) * 1000);
            } else {
                clearTimeout(this.timer);
                this.audio_selected.pause();
                this.audio_selected.currentTime = 0;
                this.audio_playing = false;
            }
        },
        selectIcon(icon) {
            if (this.selected_icon == icon && this.selected_icon != '') {
                this.selected_icon = '';
            } else {
                this.selected_icon = icon;
            }
        },
        addToFavorites(id) {
            this.phrase_selected.favorite = !this.phrase_selected.favorite;
            this.favorite_phrase = !this.favorite_phrase;
            this.get_favorites.length > 0 ? this.removeItem(this.get_favorites, id) : '';
            if (this.phrase_selected.favorite) {
                this.get_favorites.push(id);
            }
            this.$store.commit('store_favorites', this.get_favorites);
        },
        viewCountPhrase(id) {
            axios.post('/count_view/' + id).then(res => {
                if (res.data.code == 200) {}
            });
        }
    }
};
</script>
