import Vue from 'vue'
import router from './router'
import store from './store'
// get indexed db
import db from './database'

import moment from 'moment';

window.EventBus = new Vue();
import { mapState } from 'vuex'

Vue.mixin({
    data: function () {
        return {
          screen_size: window.innerWidth,
          mobile_mode: false,
          landscape_mode: false,
          tabletp_mode: false,
          desktop_mode: false,
          selected_user_type: '',
          current_language: 'en',
          translate_language: '',
          user_types: [],
          languages : [],
          pagetag: '',
          selected_phrase: null,
          current_year: (new Date).getFullYear(),
          random_number: 0,
          date_today: new Date, 
          image_banners : [],
        }
    },
    filters:{
        ucwords(value) {
            if (typeof value !== 'undefined' && value ) {
                value = value.toLowerCase();
                return value.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
                function($1) {
                    return $1.toUpperCase();
                });
            }
        },
    },
    methods: {
        to_url(url){
            router.push(url);
        },         
        onResize() {
            this.screen_size = window.innerWidth
		},
        getDaysBetweenDates(startDate, endDate) {
            var now = startDate.clone(), dates = [];
    
            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format('MM/DD/YYYY'));
                now.add(1, 'days');
            }
            return dates; 
        },
        sortItem(value) {
            if (typeof value !== 'undefined') {
                value.sort(function(a, b){
                    return new Date(a.sort_order) - new Date(b.sort_order);
                });
                return value;
            }
        },
        mapTermsCurrent(term) {
            if (term != '' && term != undefined) {
                let selected_term = this.terminologies.find(terms => terms.native_code == (term.toLowerCase()).replaceAll(' ', '_'));
                return selected_term && selected_term.formatted_translations[this.user_current_language] != '' && selected_term && selected_term.formatted_translations[this.user_current_language] != undefined ?
                       selected_term.formatted_translations[this.user_current_language] :
                       term;
            }
            return term;
        },
        mapTermsAudioTranslate(term) {
            if (term != '' && term != undefined) {
                let selected_term = this.terminologies.find(terms => terms.native_code == (term.toLowerCase()).replaceAll(' ', '_'));
                return selected_term && selected_term.formatted_audio_paths[this.user_translate_language] != '' && selected_term && selected_term.formatted_audio_paths[this.user_translate_language] != undefined ?
                       selected_term.formatted_audio_paths[this.user_translate_language] :
                       selected_term.formatted_audio_paths['en'];
            }
            return term;
        },
        mapTermsTranslate(term) {
            if (term != '' && term != undefined) {
                let selected_term = this.terminologies.find(terms => terms.native_code == (term.toLowerCase()).replaceAll(' ', '_'));
                return selected_term && selected_term.formatted_translations[this.user_translate_language] != '' && selected_term && selected_term.formatted_translations[this.user_translate_language] != undefined?
                    selected_term.formatted_translations[this.user_translate_language] :
                    term;
            }
            return term;
        },
        removeItem(array, item){
            for(var i in array){
                if(array[i]==item){
                    array.splice(i,1);
                    break;
                }
            }
        },
        inArray(needle, haystack) {
            var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] == needle) return true;
            }
            return false;
        },
        latest_date(a) {
            let latest = a.reduce((first,second) => first > second ? first : second);
            return latest.updated_at;
            let date = moment(Math.max(...a.map(e => new Date(e.updated_at)))).format('YYYY-MM-DDTHH:mm:ss');
            return date;
            return date.getFullYear() + '-' +
                   (date.getMonth() + 1) + '-' +
                   date.getDate() + 'T' +
                   date.getHours() + ':' +
                   date.getMinutes() + ':' +
                   date.getSeconds();
        },
        reassign(array, index, newValue) {
            array[index] = newValue;
            return array;
        }
    },
    computed: {
    },
    created() {
        EventBus.$on('onChangePage', (data) =>{
            this.pagetag = data;
            // let images = JSON.parse(localStorage.getItem('banner_images'));
            // this.random_number = Math.floor(Math.random() * images.length);
            this.random_number = Math.floor(Math.random() * 10);
        });
    },
    watch: {
        screen_size(newWidth, oldWidth) {
			this.mobile_mode = false;
			this.landscape_mode = false;
            this.tabletp_mode = false;
            // this.tabletl_mode = false;
            this.desktop_mode = false;
			if (newWidth <= 600 || window.innerHeight <= 425) { this.mobile_mode = true; }
			if (window.innerHeight <= 425)                    { this.landscape_mode = true; }
            if (newWidth > 600 && newWidth <= 1024)           { this.tabletp_mode = true; }
            if (newWidth > 600)                               { this.desktop_mode = true; }
        }
    },
    mounted() {
        if (this.screen_size <= 600 || window.innerHeight <= 425)   { this.mobile_mode = true; }
        if (window.innerHeight <= 425)                              { this.landscape_mode = true; }
        if (this.screen_size > 600 && this.screen_size <= 1024)     { this.tabletp_mode = true; }
        if (this.screen_size > 600)                                 { this.desktop_mode = true; }

        // console.log(this.screen_size, window.innerHeight <= 425);
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    computed: {
        ...mapState({
            user_type:  state => state.user_type,
            user_current_language: state => state.user_current_language,
            user_translate_language: state => state.user_translate_language,
            select_type_language: state => state.select_type_language,
            selected_phrase_category: state => state.selected_phrase_category,
            all_phrases_list: state => state.all_phrases_list,
            all_categories_list: state => state.all_categories_list,
            global_orig_categories: state => state.global_orig_categories,
            terminologies: state => state.terminologies,
            banner_images: state => state.banner_images,
            all_favorites: state => state.all_favorites,
            breadcrumbs: state => state.breadcrumbs,
            all_pages: state => state.all_pages,
            search_phrase_key: state => state.search_phrase_key,
            expiryDate: state => state.expiryDate,
        }),
    }    

})