<template>
    <section class="element" :class="[$route.name]">
        <v-container fluid >
            <v-row>
                <v-col>
                    <v-card class="component" :loading="loading">
                        <v-card-text>
                            <div class="faqs" v-if="type == 'listing'" >
                                <v-expansion-panels>
                                    <v-expansion-panel
                                    v-for="(item,i) in page_content"
                                    :key="i"
                                    >
                                    <v-expansion-panel-header :class="user_current_language">
                                        {{ item.translation_title }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :class="user_current_language">
                                        <div :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" v-html="item.content_translation "></div>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                            <div class="disclaimer full-width" :class="user_current_language" v-if="type == 'fullwidth'">
                                <div :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" v-html="page_content"></div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
export default {
    name: 'page',
    data() {
        return {
            type: '',
            page_content: null,
            page_content_data: null,
            loading: false
        };
    },
    created() {
        EventBus.$on('onChangeDynamicPage', (code) => {
            this.getPageContent(code);
        });
    },
    watch: {
        user_current_language() {
            this.type = this.page_content_data.type;
            this.page_content = this.page_content_data.content_per_language[this.user_current_language].translation[this.page_content_data.type].content;
            EventBus.$emit('onChangePage', this.page_content_data.content_per_language[this.user_current_language].translation[this.page_content_data.type].title);
        }
    },
    mounted() {
        this.getPageContent(this.$route.params.pagecode);
    },
    methods: { 
        getPageContent(pagecode) {
            this.loading = true;
            axios.post('/get_pagecontent/' + pagecode).then(res => {
                if (res.data.code == 200) {
                    this.loading = false;
                    this.page_content_data = res.data.data;
                    this.type = this.page_content_data.type;
                    this.page_content = this.page_content_data.content_per_language[this.user_current_language].translation[this.page_content_data.type].content;
                    if (this.page_content === '\r\n') {
                        this.page_content = this.page_content_data.content_per_language['en'].translation[this.page_content_data.type].content;
                    }
                    EventBus.$emit('onChangePage', this.page_content_data.content_per_language[this.user_current_language].translation[this.page_content_data.type].title);
                }
            });
        }
    }
};
</script>
