<template>
    <div class="day">
        <ul class="content-option top  row justify-space-between right-align">
            <li class="col-auto">
                <span class="translated">{{ mapTermsTranslate('Year') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsTranslate('Month') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsTranslate('Day') }}</span>
            </li>
        </ul>
        <div class="picker">
            <div class="picker-window"></div>
            <ul class="picker-day" id="pickeryear">
                <li v-for="year in range(year_start, current_year + 10)" :key="year">{{ year }}</li>
            </ul>
            <ul class="picker-hour" id="pickermonth">
                <li class="limonth" v-for="hr in 12" :key="hr">{{ hr }}</li>
            </ul>
            <ul class="picker-minute" id="pickerday">
                <li v-for="day in 31" :key="day">{{ day }}</li>
            </ul>
        </div>
        <ul class="content-option row justify-space-between  right-align">
            <li class="col-auto">
                <span class="translated">{{ mapTermsCurrent('Year') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsCurrent('Month') }} </span>
            </li>
            <li class="col-auto">
                <span class="translated">{{ mapTermsCurrent('Day') }}</span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'day',
    data() {
        return {
            year_start: 1920,
        }
    },
    mounted() {
        setTimeout(() => this.initiateDay(), 1000);
    },
    methods: {
        range (start, end) {
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        },
        initiateDay() {
            let height = 45;
            if (this.screen_size <= 1080 && this.screen_size >= 993) {
                height = 35;
            }
            
            let year = document.getElementById('pickeryear');
            if (year) {
                year.scrollTop = (height+0.2) * (this.current_year - this.year_start);
                // let dateToday = new Date;
                // let pickermonth = document.getElementById('pickermonth');
                // pickermonth.scrollTop = (height+0.1) * dateToday.getMonth()+1;
                // let pickerday = document.getElementById('pickerday');
                // pickerday.scrollTop = (height-0.2) * dateToday.getDate();
            }
        }
    }
}
</script>