<template>
    <v-app id="main">
        <router-view></router-view>
    </v-app>
</template>

<script>
	export default {
		data: () => ({
			drawer: false,
			db_routes: []
		}),
        created () 
        {	       
			
		},
		mounted() {
		},
		methods : {
			checkRoute(){
				
			},
			closeNavigation(value)
			{
				this.drawer = value
			}
			
		}
	}
</script>