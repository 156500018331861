<!-- 
    Select a category 
    : components/phrases/PhrasesList.vue
-->
<template>
    <section class="element">
        <v-container fluid :class="[$route.name]">
        <div class="phrases-category-list">
            <v-list dense class="py-0 navlist row" link> 
                <div class="col-md-6 col-12" v-for="(item, i) in paginate_phrase_list[current_page]" :key="i">
                    <v-list-item class="nav-item" @click="selectPhrase(item)">
                        <v-list-item-content :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" :class="user_current_language == 'ar' || user_current_language == 'pr' ? 'text-right' : '' ">
                            <v-list-item-title>{{ item.formatted_translations[user_current_language] !== undefined ?
                                (item.formatted_translations[user_current_language] != '' ? item.formatted_translations[user_current_language] : item.native)
                                : item.native }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon small v-if="item.favorite" class="added">mdi-heart</v-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </div>
                <!-- <div class="text-center col-12 pt-5 has-image is-square" v-if="filter_phrases && filter_phrases.length <= 0 && filter == 'all'"></div> -->
                <div class="no-data col-10 col-sm-8 col-lg-6" :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" v-if="filter_phrases && filter_phrases.length <= 0 && filter == 'favorites'">
                    <p>{{ mapTermsCurrent('If there is a phrase you use a lot, or if you would like to write a paragraph, use the Favourites function') }} (<v-icon class="added" small>mdi-heart</v-icon>) </p>
                </div>
                <div class="col-12 filters d-flex-reverse pagination" v-if="page > 1">
                    <label :class="current_page == 1 ? 'active' : ''" @click="current_page = 1"><v-icon>mdi-chevron-double-left</v-icon></label>
                    <label @click="current_page > 1 ? current_page = current_page - 1 : ''"><v-icon>mdi-chevron-left</v-icon></label>
                    <label >{{ current_page }}/{{ page }}</label>
                    <label @click="current_page < page ? current_page = current_page + 1 : ''"><v-icon>mdi-chevron-right</v-icon></label>
                    <label :class="current_page == page ? 'active' : ''" @click="current_page = page"><v-icon>mdi-chevron-double-right</v-icon></label>
                </div>
            </v-list>
        </div>
        </v-container>
    </section>
    
</template>
<script>
export default {
    data() {
        return {
            filter_phrases: [],
            paginate: 24,
            page: 0,
            paginate_phrase_list: [],
            current_page: 1,
            search_text: '',
        };
    },
    watch: {
        filter() {
            this.current_page = 1;
            this.loadFilterPhrases();
            this.page = Math.ceil(this.filter_phrases.length / this.paginate);
        },
        data() {
            this.filter_phrases = this.data;
            // this.current_page = 1;
            this.loadFilterPhrases();
            this.page = Math.ceil(this.filter_phrases.length / this.paginate);
        },
        user_type() {
            if (typeof this.filter !== undefined && this.filter != 'all') {
                this.loadFilterPhrases();
            } else {
                this.loadAllPhrases();
            }
            this.current_page = 1;
            this.page = Math.ceil(this.filter_phrases.length / this.paginate);
        },
        search_text() {
            if (this.$route.name != 'translation' && this.search_text != '' && typeof this.search_text !== undefined) {
                if (typeof this.filter !== undefined && this.filter != 'all') {
                    this.loadFilterPhrases(); 
                } else {
                    this.loadAllPhrases();
                }
                this.current_page = 1;
                this.page = Math.ceil(this.filter_phrases.length / this.paginate);
            } else {
                this.loadAllPhrases();
            }
        },
        search_phrase_key() {
            this.search_text = this.search_phrase_key;
        },
        current_page() {
            // console.log('page ', this.current_page);
        }
    },
    props: {
        filter: String,
        data: Array,        
    },
    created() {        
        EventBus.$on('onBackCurrentPage', (data) => {
            this.current_page = parseInt(localStorage.getItem('current_page'));
        });
    },
    mounted() {
        this.filter_phrases = this.data;
        // await this.getLatestPhrase();
        if (localStorage.getItem('current_page') && this.$route.name == 'all-phrases' || this.$route.name == 'phrases') {
            this.current_page = parseInt(localStorage.getItem('current_page'));
        }
        this.loadAllPhrases();
        this.search_text = this.search_phrase_key;
    },
    methods: {
        getLatestPhrase() {
            if (this.all_phrases_list.length > 0) {
                let latest_date = this.latest_date(this.all_phrases_list);
                    
                axios.post('/get_latest_phrases/'+ latest_date ).then(res => {
                    if (res.data.code == 200) {
                        let all_phrases = this.all_phrases_list;
                        res.data.data.phrase_list.forEach(element => {
                            let index = this.all_phrases_list.findIndex(phrase => phrase.id == element.id);
                            all_phrases = this.reassign(all_phrases, index, element);
                            if (index <= -1) {
                                all_phrases.push(element);
                            }
                        });
                        this.$store.commit('store_phrases', all_phrases.filter(phrase => phrase.published == 1));
                    }
                });
                        
            }
        },
        getPageNumber(index) {
            for (let i = 1; i <= this.page; i++) {
                if (index >= i && index <= (i * this.paginate)) {
                    return i;
                }
            }
        },
        loadAllPhrases() {
            this.filter_phrases = this.data.map(phrase => {
                                                                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                return phrase
                                                         });
            if (this.$route.name == 'phrases') {
                this.filter_phrases = this.data.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == this.selected_phrase_category.id) != null)
                                                         .map(phrase => {
                                                                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                return phrase
                                                         });
            }
            if(this.search_text) {
                if (this.user_current_language == 'en') {
                    this.filter_phrases = this.data.filter(phrase => phrase.formatted_translations[this.user_current_language].toLowerCase().indexOf(this.search_text.toLowerCase()) > -1);
                } else {
                    // this.filter_phrases = this.data.filter(phrase => (phrase.formatted_translations[this.user_current_language]).search(this.search_text) > -1);
                    this.filter_phrases = this.data.filter((phrase) =>  {
                    let search = new String(phrase.formatted_translations[this.user_current_language]).toLocaleLowerCase();
                        return search.search(this.search_text.toLocaleLowerCase()) > -1;
                    });
                }
                
                // this.filter_phrases = this.data.filter((phrase) => { 
                //     console.log(phrase.formatted_translations[this.user_current_language].indexOf(this.search_text) > -1);
                //     // phrase.formatted_translations[this.user_current_language].toLowerCase().indexOf(this.search_text.toLowerCase()) > -1;
                // });
            }
            if(this.filter == 'favorites') {
                this.filter_phrases = this.filter_phrases.filter(phrase => this.filter == 'favorites' && phrase.favorite);
            }
            this.filter_phrases = this.filter_phrases.filter(phrase => phrase.usertypes.find(type => type.id == this.user_type) != null)
                                            .map((phrase, index) => {
                                                this.page = Math.ceil(this.filter_phrases.length / this.paginate);
                                                phrase.page_number = this.getPageNumber(index + 1);
                                                return phrase;
                                            });
            this.paginate_phrase_list = _.groupBy(this.filter_phrases, 'page_number');
        },
        selectPhrase(phrase) {
            // this.search_phrase_key = '';
            // EventBus.$emit('onRecordCrumbs', { native_code: 'Translate a phrase', name: this.mapTermsCurrent('Translate a phrase'), link: 'phrases' });
            this.$router.push('/translation?id='+phrase.id);
            localStorage.setItem('current_page', phrase.page_number);
        },
        loadFilterPhrases() {
            this.filter_phrases = this.data.map(phrase => {
                                                                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                return phrase
                                                         });
            if (this.$route.name == 'phrases') {
                this.filter_phrases = this.data.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == this.selected_phrase_category.id) != null)
                                                         .map(phrase => {
                                                                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                return phrase
                                                         });
            }                                
            
            
            if(this.search_text) {
                if (this.user_current_language == 'en') {
                    this.filter_phrases = this.filter_phrases.filter(phrase => phrase.formatted_translations[this.user_current_language].toLowerCase().indexOf(this.search_text.toLowerCase()) > -1);
                } else {
                    // this.filter_phrases = this.data.filter(phrase => (phrase.formatted_translations[this.user_current_language]).search(this.search_text) > -1);
                    this.filter_phrases = this.filter_phrases.filter((phrase) =>  {
                        let search = new String(phrase.formatted_translations[this.user_current_language]).toLocaleLowerCase();
                        return search.search(this.search_text.toLocaleLowerCase()) > -1;
                    });
                }
            }

            if (typeof this.filter !== undefined && this.filter != 'all') {
                this.filter_phrases = this.filter_phrases.filter(phrase => phrase.usertypes.find(type => type.id == this.user_type) != null)
                                                         .filter(phrase => this.filter == 'favorites' && phrase.favorite)
                                                         .map((phrase, index) => {
                                                            this.page = Math.floor(this.filter_phrases.length / this.paginate);
                                                            phrase.page_number = this.getPageNumber(index + 1);
                                                            return phrase;
                                                         });
            }
            
            this.filter_phrases = this.filter_phrases.filter(phrase => phrase.usertypes.find(type => type.id == this.user_type) != null)
                                           .map((phrase, index) => {
                                                this.page = Math.ceil(this.filter_phrases.length / this.paginate);
                                                phrase.page_number = this.getPageNumber(index + 1);
                                                return phrase;
                                           });        
            this.paginate_phrase_list = _.groupBy(this.filter_phrases, 'page_number');
        }
    }
};
</script>
