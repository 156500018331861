<template>
  
        <div id="app" :class="['body page-' + $route.name, isHome || isUserSelection ? 'home' : '', mobile_mode ? 'mobile' : 'desktop', landscape_mode ? 'landscape' : '']">

            <!-- :start Nav -->         
            <navigation-header v-if="!isHome && !isUserSelection"></navigation-header>  
            <!-- :end Main -->
    
            <!-- :start Desktop -->  
            <v-main class="b-white">
                <v-row class="dashboard no-gutters justify-end" v-if="!mobile_mode && !isHome">
                    <v-col cols="4" sm="5" lg="3" class="pa-0 dash--nav">
                        <v-carousel hide-delimiters cycle :loop="true" :interval="8000" :show-arrows="false">
                            <v-carousel-item
                            v-for="(item,i) in banner_images_live"
                            :key="i"
                            :src="item"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                            ></v-carousel-item>
                        </v-carousel>
                        <div class="pr-10 h-100 has-content">
                            <div class="b-primary h-100" :class="user_current_language">
                                <global-search :aplacholder="mapTermsCurrent('Search by title/keyword, e.g. interpreter')"></global-search>
                                <v-row class="quicklinks" v-if="quicklinks_menu">
                                    <v-col class="item" :class="user_current_language" cols="12" v-for="(menu, i) in quicklinks_menu.pages_translations" :key="i" >
                                        <span v-if="menu.formatted_translations" @click="gotoDynamicPage(menu.page_code)">{{ menu.formatted_translations[user_current_language] != '' && menu.formatted_translations[user_current_language] != undefined ? menu.formatted_translations[user_current_language] : menu.native }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="8" sm="7" lg="9" class="dash--content">
                        <v-row class="breadcrumbs">
                            <v-col cols="12" >
                                <v-col>
                                    <v-row class="justify-space-between">
                                        <v-col class="col-auto">
                                            <label class="back-btn" @click="goBack()"><v-icon>mdi-chevron-left</v-icon><span :class="user_current_language">{{ mapTermsCurrent('Back') }}</span> </label>
                                        </v-col>
                                        <v-col class="col-auto">
                                            <span :class="user_current_language">{{ mapTermsCurrent('User type selected')}}: {{ select_type_language && select_type_language.type && select_type_language.type.length > 0 ? select_type_language.type.find(type => type.id == user_type).formatted_translations[user_current_language] : '' }}</span>
                                        </v-col>
                                    </v-row>
                                    <!-- <small v-if="!isHome && $route.name == 'select-category' || $route.name == 'translation' || $route.name == 'phrases'" class="crumbs" @click="to_url('/')"> <label :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''"> {{ mapTermsCurrent('Select a category')}} </label></small>
                                    <small class="crumbs" v-for="(crumbs, i) in breadcrumbs" :key="i" @click="crumbClick(crumbs, i)">
                                        <span> / </span>
                                        <label :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''" :class="breadcrumbs.length == (i+1) ? 'last' : ''">{{ crumbs.name }}</label>
                                    </small> -->
                                    <h3 class="sectitle" :dir="user_current_language == 'ar' || user_current_language == 'pr' ? 'rtl' : ''">{{ mapTermsCurrent(pagetag) }}</h3>
                                </v-col>
                            </v-col>
                        </v-row>

                        <transition name="slide">
                            <router-view></router-view>
                        </transition>
                        <!-- :start Desktop > Footer -->
                        <v-col cols="8" sm="7" lg="9" class="dash--footer svhm-com m50t">
                            <v-row class="align-center justify-space-between">
                                <v-col sm="12" md="auto" class="text-center text-sm-left align-center">
                                    <img draggable="false" class="size-two mr-5" src="../assets/images/city-of-melbourne.png" alt="City of Melbourne">
                                    <img draggable="false" class="size-two" src="../assets/images/logo-svha.png" alt="Talk to Me">
                                </v-col>
                                <v-col sm="12" md="auto">
                                    <span>© {{ (new Date).getFullYear() }} St Vincent’s Hospital Melbourne.</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- :end Desktop > Footer -->
                    </v-col>
                </v-row>
                <!-- :end Desktop -->

                <!-- :end Mobile -->
                <transition name="slide" v-if="isHome || mobile_mode">
                    <router-view></router-view>
                </transition>
                <!-- :end Mobile -->
            </v-main>
           
        </div>
    </div>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});

export default {
    name: 'dashboard',
    data: () => ({
        drawer: true,
        db_routes: [],
        dashboard_breadcrumb: [],
        quicklinks_menu: [],
        dashboard_random: 0,
        height: 0,
        banner_images_live: [require('../assets/images/doctorpatient.jpg')],
        // global_orig_categories: [],
        global_phrases_categories: [],
        dashboard_phrases_categories: []
    }),
    async mounted() {
        await this.checkSelection();
        const scrollPos = window.scrollY;
        window.scrollTo(0, scrollPos);

        // this.dashboard_breadcrumb = this.breadcrumbs;
        this.dashboard_random = this.random_number;
        this.height = 800;
        if (window.innerWidth >= 1900) this.height = 900;
        this.getPages();
		this.quicklinks_menu = this.all_pages.find(page => page.code == 'quicklinks_menu');
        this.getTypeLanguages();
        this.getTerms();
        await this.getLatestPhrase();
        // await this.getDeletedPhrase();
        // this.getLatestCategories();
        
        this.dashboard_phrases_categories = this.all_categories_list;
        // this.getCategoriesPhrases();        
        
		// this.getImages();

        /** Remove auto expired */
        // if (this.expiryDate == '') {
        //     this.$store.commit('store_expiry_date');
        // }
        // if (this.$store.getters.isExpired) {
        //     this.$store.commit('store_selection', []);
        //     this.$store.commit('store_category_selection', []);
        //     this.$store.commit('store_phrases', []);
        //     this.$store.commit('store_categories', []);
        //     this.$store.commit('store_orig_categories', []);
        //     this.$store.commit('store_favorites', []);
        //     // this.$store.commit('store_crumbs', []);
        //     this.$store.commit('store_phrase_search', []);
        //     this.$store.commit('store_expiry_date');
        //     await this.checkSelection();
        // }
        /** Remove auto expired */
    },
    computed: {
        isHome() {
            return this.$route.name === 'home';
        },
        isUserSelection() {
            return this.$route.name === 'userselection';
        },
        isCategory() {
            return this.$route.name === 'select-category';
        }
    },
    watch: {
        user_current_language() {
            // this.dashboard_breadcrumb = this.dashboard_breadcrumb.map(crumb => {
            //     crumb.name = this.mapTermsCurrent(crumb.native_code);
            //     return crumb;
            // });
            // this.$store.commit('store_crumbs', this.dashboard_breadcrumb);
        },
        // breadcrumbs() {
        //     this.dashboard_breadcrumb = this.breadcrumbs;
        // },
        all_pages() {
            this.quicklinks_menu = this.all_pages.find(page => page.code == 'quicklinks_menu');
        },
        random_number() {
            this.dashboard_random = this.random_number;
        },
        user_type() {
            // this.getLatestPhrase();
            // this.getLatestCategories();
        },
        all_categories_list() {
            this.dashboard_phrases_categories = this.all_categories_list;
        },
    },
    created() {        
        EventBus.$on('onRecordCrumbs', (data) => {
            // = this.dashboard_breadcrumb.filter(crumb => crumb.link != data.link)
            // this.dashboard_breadcrumb ? this.dashboard_breadcrumb : '';
            // this.dashboard_breadcrumb.push(data);
            // this.$store.commit('store_crumbs', this.dashboard_breadcrumb);
        });
        
        EventBus.$on('onClickPhrasesCategories', (data) => {
            // this.getLatestPhrase();
            // this.getLatestCategories();
        });    
        EventBus.$on('onLoadedCategory', (data) => {
            // this.getLatestPhrase();
            // this.getLatestCategories();
            this.getImages();
        });            
    },
    methods: {
        async phrase_data() {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            return all_phrase;
        },
        getLatestPhrase() {
            if ((this.phrase_data()).length > 0) {
                let latest_date = this.latest_date((this.phrase_data)[0]);
                    
                axios.post('/get_latest_phrases/'+ latest_date ).then(res => {
                    if (res.data.code == 200) {
                        let all_phrases = (this.phrase_data)[0];
                        res.data.data.phrase_list.forEach(element => {
                            let index = (this.phrase_data)[0].findIndex(phrase => phrase.id == element.id);
                            all_phrases = this.reassign(all_phrases, index, element);
                            if (index <= -1) {
                                all_phrases.push(element);
                            }
                        });
                        // this.$store.commit('store_phrases', all_phrases);
                        
                        let latest_date = this.latest_date(all_phrases);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: all_phrases.filter(phrase => phrase.published == 1)});
                        EventBus.$emit('onPhraseLoaded', true);
                    }
                });
                        
            }
        },
        // getDeletedPhrase() {
        //     if ((this.phrase_data()).length > 0) {
        //         axios.post('/get_deleted_phrases').then(res => {
        //             if (res.data.code == 200) {
        //                 let all_phrases = (this.phrase_data)[0];
        //                 res.data.data.phrase_list.forEach(element => {
        //                     all_phrases = all_phrases.filter(phrase => phrase.id != element.id);
        //                 });
        //                 this.loading = false;
                        
        //                 let latest_date = this.latest_date(all_phrases);
        //                 db.phrases_translation.put({key: 0, updated: latest_date, phrases: all_phrases.filter(phrase => phrase.published == 1)});
        //                 EventBus.$emit('onPhraseLoaded', true);
        //             }
        //         });                        
        //     }
        // },
        getCategoriesPhrases() {
            if (this.dashboard_phrases_categories.length <= 0) { 
                axios.post('/get_categories').then(res => {
                    if (res.data.code == 200) {
                        this.orig_categories = res.data.data.category_list;
                        this.dashboard_phrases_categories = this.orig_categories.map(category => {
                            category.phrases = res.data.data.phrase_list.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == category.id) != null)
                                                                                        // phrase.usertypes.find(type => type.id == this.user_type) != null)
                                                                        .map(phrase => {
                                                                            phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                            return phrase
                                                                        }).length;
                            return category;
                        });                    
                        this.$store.commit('store_orig_categories', this.orig_categories);
                        // this.$store.commit('store_phrases', res.data.data.phrase_list);
                        this.$store.commit('store_categories', this.dashboard_phrases_categories.filter(cat => cat.phrases > 0));
                        
                        let latest_date = this.latest_date(res.data.data.phrase_list);
                        db.phrases_translation.put({key: 0, updated: latest_date, phrases: res.data.data.phrase_list});
                        this.phrase_data();
                        EventBus.$emit('onPhraseLoaded', true);
                        EventBus.$emit('onLoadingData', false);
                    }
                });
            }
        },     
        getLatestCategories() {
            if (this.all_categories_list.length > 0) {
                let latest_date = this.latest_date(this.all_categories_list);
                axios.post('/get_latest_categories/'+ latest_date).then(res => {
                    if (res.data.code == 200) {
                        let all_categories_list = this.global_orig_categories;
                        
                        res.data.data.categories.forEach(element => {
                            let index = this.global_orig_categories.findIndex(category => category.id == element.id);
                            all_categories_list = this.reassign(all_categories_list, index, element);
                            if (index <= -1) {
                                all_categories_list.push(element);
                            }
                        });
                        this.global_phrases_categories = all_categories_list.map(category => {
                            category.phrases = this.all_phrases_list.filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == category.id) != null && 
                                                                                    phrase.usertypes.find(type => type.id == this.user_type) != null)
                                                                .map(phrase => {
                                                                    phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                                                                    return phrase
                                                                });
                            return category;
                        });     
                        this.$store.commit('store_categories', this.global_phrases_categories.filter(cat => cat.phrases.length > 0));
                    }
                });
            }
        },
        getTypeLanguages() {
            axios.post('/get_usertypes_and_languages').then(res => {
                if (res.data.code == 200) {
                    let select_type_language = {};
                    select_type_language.type = res.data.data.usertypes;
                    select_type_language.languages = res.data.data.languages;
                    
                    this.$store.commit('store_type_languages', select_type_language);
                }
            });
        },
        getTerms() {
            axios.post('/get_terms').then(res => {
                if (res.data.code == 200) {					
                    this.$store.commit('store_terms', res.data.data);
                }
            });
        },
		getPages() {
			axios.post('/get_menus').then(res => {
				if (res.data.code == 200) {
					this.$store.commit('store_pages', res.data.data);
				}
			});
		},
        goBack() {
            // if (this.$route.name === 'translation') {
            //     EventBus.$emit('onBackCheckPage', 1);
            // } else {
                this.$router.go(-1);
            // }
        },
        closeNavigation(value) {
            this.drawer = value
        },
        crumbClick(crumb, key) {
            if (this.dashboard_breadcrumb.length != key+1) {
                if (crumb.category !== undefined && crumb.category) {
                    // console.log('must',crumb.link);
                }
                if (crumb.link == 'phrases') {
                    // this.dashboard_breadcrumb.pop()
                    // this.$store.commit('store_crumbs',this.dashboard_breadcrumb);
                    this.$router.go(-1);
                }
                if (crumb.link == 'select-category') {
                    // this.$store.commit('store_crumbs', []);
                    if (this.$route.name != 'select-category') {
                        this.$router.push('/'+crumb.link);
                    } else {
                        EventBus.$emit('onBackCategory', null);
                    }
                }
            }
        },
        gotoDynamicPage(code) {
            let static_pages = ['all_phrases', 'find_phrase','favourites'];
            if(this.inArray(code, static_pages)) {
                if (code == 'all_phrases' && this.$route.name != 'all-phrases') {
                    this.$router.push('/all-phrases');
                }
                if (code == 'find_phrase' && this.$route.name != 'select-category') {
                    this.$router.push('/categories');
                }
                if (code == 'favourites') {
                    if(this.$route.name != 'all-phrases') {
                        // EventBus.$emit('onLoadFavourites', []);
                        this.$router.push('/all-phrases?favorites=1');
                    } else {
                        EventBus.$emit('onLoadFavourites', []);
                    }
                }
            } else {
                if (this.$route.name != 'page') {
                    this.$router.push('/page/'+ code);
                } else {
                    if (this.$route.params.pagecode != code ) {
                        EventBus.$emit('onChangeDynamicPage', code);
                        this.$router.push('/page/'+ code);
                    }
                }
            }
        },
		getImages() {
			axios.post('/get_images_raw').then(res => {
				if (res.data.code == 200) {
                    // console.log(res.data.data.image_gallery);
                    // this.banner_images_live = res.data.data.image_gallery.map(image => 'data:image/jpeg;base64,'+image.base64);
					// this.$store.commit('store_images', res.data.data.image_gallery.map(image => image.path));
                    const mapLoop = async _ => {
                        res.data.data.image_gallery.map(async image => {
                            let image_path = await this.getBase64FromUrl(image.path);
                            // console.log(image_path);
                            this.banner_images_live.push(image_path);
                            return image_path
                        });
                        // console.log(this.banner_images_live);
                        // promises
                        // const images = await Promise.all(promises)
                        // this.banner_images_live = images;
                        // return this.banner_images_live;
                    };
                    mapLoop();
				}
			});
		},
        async getBase64FromUrl(url) {
            // const data = await fetch(url, { mode: 'no-cors', method: 'POST', headers: {'Content-Type': 'image/jpeg'} });
            const data = await fetch(url, { method: "POST" });
            const blob = await data.blob();
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); 
                    reader.onloadend = () => {
                    const base64data = reader.result;   
                    resolve(base64data);
            }
        });
        },
        checkSelection() {
            if ((!this.user_type || !this.user_current_language || !this.user_translate_language) && this.$route.name != 'home') {
                this.$router.push('/');
            }
        }

    },
};
</script>