<template>
    <div :style="{minHeight: '500px', marginTop: landscape_mode ? '120px' : ''}">
        <!-- <mobile-search :pagename="page_name" v-if="mobile_mode"></mobile-search> -->
        <phrases-list :data="filtered_phrases" :filter="'all'" :class="false ? 'pt-5' : ''"></phrases-list>
    </div>
</template>
<script>
import Dexie from 'dexie';

const db = new Dexie('phrases-translation')
db.version(1).stores({
    phrases_translation: `&key, updated, phrases`
});

export default {
    name: 'phrases',
    data() {
        return {
            filtered_phrases: [],
            all_phrases_get: [],
        };
    },
    watch: {
        all_phrases_list() {
            this.loadPhrases();
        },
        user_type() { 
            this.loadPhrases();
        }
    },
    async mounted() {
        EventBus.$emit('onChangePage', 'Search for a phrase');
        await this.fetchData();
        this.loadPhrases();
    },
    computed: {
        page_name() {
            return this.$route.name;
        }
    },
    methods: { 
        async fetchData () {
            let all_phrase = {}
            await db.phrases_translation.toCollection().each(d => {
                all_phrase[d.key] = d.phrases
            })
            this.all_phrases_get = all_phrase;
        },
        loadPhrases() {
            this.filtered_phrases = this.all_phrases_get[0].filter(phrase => phrase.categories.find(phrase_cat => phrase_cat.id == this.selected_phrase_category.id) != null)
            .map(phrase => {
                phrase.favorite = this.all_favorites.find(favorite => favorite == phrase.id) ? true : false;
                return phrase
            });
        }
    }
};
</script>
